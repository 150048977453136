import {
    FEATURED_OUTSTANDING_REQUEST, FEATURED_OUTSTANDING_SUCCESS, FEATURED_OUTSTANDING_ERROR,
    FEATURED_MULTIPLAYER_REQUEST, FEATURED_MULTIPLAYER_SUCCESS, FEATURED_MULTIPLAYER_ERROR,
    FEATURED_MULTIVIEW_REQUEST, FEATURED_MULTIVIEW_SUCCESS, FEATURED_MULTIVIEW_ERROR,
    FEATURED_THEMES_REQUEST, FEATURED_THEMES_SUCCESS, FEATURED_THEMES_ERROR,
    FEATURED_CONTESTS_REQUEST, FEATURED_CONTESTS_SUCCESS, FEATURED_CONTESTS_ERROR
} from '../constants/constants';

const initialState = {
    loadingOutStanding: true,
    errorOutStanding: '',
    projectsOutStanding: [],

    loadingMultiPlayer: true,
    errorMultiPlayer: '',
    projectsMultiPlayer: [],

    loadingMultiView: true,
    errorMultiView: '',
    projectsMultiView: [],

    loadingThemes: true,
    errorThemes: '',
    themes: [],

    loadingContests: true,
    errorContests: '',
    contests: [],





}

const featuredReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEATURED_OUTSTANDING_REQUEST:
            return {
                ...state,
                projectsOutStanding: [],
                loadingOutStanding: true
            };
        case FEATURED_OUTSTANDING_SUCCESS: {
            return {
                ...state,
                loadingOutStanding: false,
                projectsOutStanding: action.data.projectsOutStanding,
                errorOutStanding: '',
            };
        }
        case FEATURED_OUTSTANDING_ERROR: {
            return {
                ...state,
                loadingOutStanding: false,
                projectsOutStanding: [],
                errorOutStanding: action.data.errorOutStanding
            }
        }
        case FEATURED_MULTIPLAYER_REQUEST:
            return {
                ...state,
                projectsMultiPlayer: [],
                loadingMultiPlayer: true
            };
        case FEATURED_MULTIPLAYER_SUCCESS: {
            return {
                ...state,
                loadingMultiPlayer: false,
                projectsMultiPlayer: action.data.projectsMultiPlayer,
                errorMultiPlayer: '',
            };
        }
        case FEATURED_MULTIPLAYER_ERROR: {
            return {
                ...state,
                loadingMultiPlayer: false,
                projectsMultiPlayer: [],
                errorMultiPlayer: action.data.errorMultiPlayer
            }
        }
        case FEATURED_MULTIVIEW_REQUEST:
            return {
                ...state,
                projectsMultiView: [],
                loadingMultiView: true
            };
        case FEATURED_MULTIVIEW_SUCCESS: {
            return {
                ...state,
                loadingMultiView: false,
                projectsMultiView: action.data.projectsMultiView,
                errorMultiView: '',
            };
        }
        case FEATURED_MULTIVIEW_ERROR: {
            return {
                ...state,
                loadingMultiView: false,
                projectsMultiView: [],
                errorMultiView: action.data.errorMultiView
            }
        }
        //theme

  
        case FEATURED_THEMES_REQUEST:
            return {
                ...state,
                themes: [],
                loadingThemes: true
            };
        case FEATURED_THEMES_SUCCESS: {
            return {
                ...state,
                loadingThemes: false,
                themes: action.data.themes,
                errorThemes: '',
            };
        }
        case FEATURED_THEMES_ERROR: {
            return {
                ...state,
                loadingThemes: false,
                themes: [],
                errorThemes: action.data.errorThemes
            }
        }
        //contests
    
        case FEATURED_CONTESTS_REQUEST:
            return {
                ...state,
                contests: [],
                loadingContests: true
            };
        case FEATURED_CONTESTS_SUCCESS: {
            return {
                ...state,
                loadingContests: false,
                contests: action.data.contests,
                errorContests: '',
            };
        }
        case FEATURED_CONTESTS_ERROR: {
            return {
                ...state,
                loadingContests: false,
                contests: [],
                errorContests: action.data.errorContests
            }
        }
        default:
            return state;
    }
};
export default featuredReducer;