exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".step-bar-component_stepBar_1O1lb {\n    width: 100%;\n    max-width: 850px;\n    margin: auto;\n}", ""]);

// exports
exports.locals = {
	"stepBar": "step-bar-component_stepBar_1O1lb"
};