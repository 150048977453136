import {  IS_AUTH_IN_EDITOR, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_FAIL, REMOVE_USER_PROFILE, UPDATE_LOCAL_USER_PROFILE, UPDATE_LOCAL_USER_AVATAR } from "../constants/constants";

const initialState = {
    token: "",
    id: null,
    username: "",
    fullname: "",
    // phonenumber: "",
    avatar: "",
    loggedIn: false,
    authError: "",
    uniq_random_code:"",
    isAuthInEditor: false,
    isRegisterInEditor: false,
}

const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                token: action.data.token,
                id: action.data.id,
                username: action.data.username,
                fullname: action.data.fullname,
                // phonenumber: action.data.phonenumber,
                avatar: action.data.avatar,
                loggedIn: true,
                authError: "",
                uniq_random_code:action.data.uniq_random_code

            }
        case GET_USER_PROFILE_FAIL:
            return {
                ...state,
                token: "",
                id: "",
                username: "",
                fullname: "",
                // phonenumber: "",
                avatar: "",
                loggedIn: false,
                authError: action.data.error,
                uniq_random_code:""
            }
        case REMOVE_USER_PROFILE:
            return {
                ...state,
                token: "",
                id: "",
                username: "",
                fullname: "",
                // phonenumber: "",
                avatar: "",
                loggedIn: false,
                authError: "",
                isAuthInEditor: false,
                uniq_random_code:""
            }
        case UPDATE_LOCAL_USER_PROFILE:
            return {
                ...state // chưa dùng
            }
        case UPDATE_LOCAL_USER_AVATAR:
            return {
                ...state,
                avatar: action.data.avatar
            }
        case IS_AUTH_IN_EDITOR:
            return {
                ...state,
                isAuthInEditor: action.data.isAuthInEditor
            }
      
        default:
            return state;
    }

}
export default authReducer;