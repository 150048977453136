exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /*$ui-secondary: hsla(215, 75%, 95%, 1); !* #E9F1FC *!*/ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /*Code màu cũ*/ /*$motion-primary: hsla(215, 100%, 65%, 1); !* #4C97FF *!*/ /*$motion-tertiary: hsla(215, 60%, 50%, 1); !* #3373CC *!*/ /*$motion-transparent: hsla(215, 100%, 65%, 0.35); !* 35% transparent version of motion-primary *!*/ /*$motion-light-transparent: hsla(215, 100%, 65%, 0.15); !* 15% transparent version of motion-primary *!*/ /*Code màu mới (màu CodeKitten)*/ /*Code mau header vang nau*/ /*$motion-primary: hsl(164, 44%, 55%); !* #4C97FF *!*/ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighscratchCategoryMenuter than motion-primary */ /*CodeKitten custom colours*/ /* 35% transparent version of motion-primary */ .waveform_container_2K9Om {\n    width: 100%;\n} .waveform_waveform-path_TskyB {\n    /*\n        This color is lighter than sound-primary, but\n        cannot use alpha because of overlapping elements.\n    */\n    fill: hsl(300, 54%, 72%);\n    stroke: hsla(300, 48%, 50%, 1);\n} .waveform_baseline_2J5dw {\n    stroke: hsla(300, 48%, 50%, 1);\n}\n", ""]);

// exports
exports.locals = {
	"container": "waveform_container_2K9Om",
	"waveform-path": "waveform_waveform-path_TskyB",
	"waveformPath": "waveform_waveform-path_TskyB",
	"baseline": "waveform_baseline_2J5dw"
};