
import axiosClient from "./axios-client";
const authApi = {
    
    // Axios methods for REGISTER feature
    registerUserInfo: (mobile_number, username) => {
        const url = '/v1/auth/mobile_otp_registration';
        //  return response
        return axiosClient.post(url, {
            mobile_number: mobile_number,
            full_name: username
        });
    },
    // send OTP to server
    verifyOTP: (sms_code, mobile_number) => {
        const url = '/v1/auth/verify_mobile_otp_registration';

        return axiosClient.post(url, {
            sms_code: sms_code,
            mobile_number: mobile_number
        });
    },
    // set password after sucessful OTP verification
    registerPassword: (regis_code, password, utm_source) => {
        const url = '/v1/auth/registration_password';

        return axiosClient.post(url, {
            registration_code: regis_code,
            password: password,
            utm_source: utm_source,
            client_id: process.env.CLIENT_ID,
            client_secret: process.env.CLIENT_SECRET
        });
    },
    
    /*Axios methods: forget and then reset new password 
    */
    forgotPasswordByPhone: (mobile_number) => {
        const url ="/v1/auth/forgot_by_phone";

        return axiosClient.post(url, {
            mobile_number: mobile_number
        })
    },

    verifyOTPForgotPass: (mobile_number, sms_code) => {
        const url = "/v1/auth/verify_forgot_by_phone";
        return axiosClient.post(url, {
            mobile_number: mobile_number,
            sms_code: sms_code,
        })
    },
    setPasswordForgotPW: (forgot_token, password) => {
        const url = "/v1/auth/forgot_set_password";
        return axiosClient.post(url, {
            forgot_token: forgot_token,
            password: password,
            client_id: process.env.CLIENT_ID,
            client_secret: process.env.CLIENT_SECRET
        })
    },

    getProfile: (token) => {
        const url = '/v1/auth/profile';
        // const header = 
        // {

        //     Authorization: "Bearer " + localStorage.getItem(ConfigKey.token),
        // }

        return axiosClient.get(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }});
    },
    getProfileById: (userId) => {
        const url = `/api/v1/users/${userId}/profile`;
        return axiosClient.get(url);
    },
     getProfileByUserCode: (userCode) => {
        const url = `api/v1/users/user_profile/${userCode}`;
        return axiosClient.get(url);
    },

    login: (data) => {
        const url = '/v1/auth/login';
        return axiosClient.post(url,
            data);
    },
    logout: (token) => {
        const url = "/v1/auth/logout";
        const header =
        {
            "Authorization": `Bearer ${token}`,
        }

        return axiosClient.post(url, null, 
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }});
    },
}

export default authApi;