import { GET_TUTORIALS_IN_PAGE_REQUEST, GET_TUTORIALS_IN_PAGE_SUCCESS, GET_TUTORIALS_IN_PAGE_FAIL } from "../constants/constants";
const initialState = {
    loadingTutorials: true,
    currentPage: 1,
    totalPage: 1,
    tutorials: [],
    error: ""
}

const tutorialsReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_TUTORIALS_IN_PAGE_REQUEST:
            return {
                ...state,
                loadingTutorials: true,
            }
        case GET_TUTORIALS_IN_PAGE_SUCCESS:
            return {
                ...state,
                loadingTutorials: false,
                currentPage: action.data.currentPage,
                totalPage: action.data.totalPage,
                tutorials: action.data.tutorials,
                error: ""
            }
        case GET_TUTORIALS_IN_PAGE_FAIL:
            return {
                ...state,
                loadingTutorials: false,
                error: action.data.error
            }
        default:
            return state; 
    }
}

export default tutorialsReducer;