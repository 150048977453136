
import { createStore, applyMiddleware,combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import thunk from 'redux-thunk';
// import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import homeReducer from './home-reducer/home-reducer';
import guiReducer from './gui-reducer/gui-reducer';
import profileReducer from "./profile-reducer/userprofile-reducer";
import featuredReducer from './projects-featured-reducer/featured-reducer';
import listProjectReducer from './list-projects-reducer/list-projects-reducer';
import {projectEditorReducer} from './gui-reducer/gui-reducer';
import registerReducer from "./register-reducer/register-reducer";
import RegisterSourceReducer from './register-reducer/register-source-reducer';
import forgotpassReducer from './forgot-password-reducer/forgot-password-reducer';
import authReducer from "./auth-reducer/auth-reducer";
import tutorialsReducer from './tutorials-reducer/tutorials-reducer';
import contestReducer from './contest-reducer/contest-reducer';
import { loadingBarReducer } from 'react-redux-loading-bar'
import dataProjectReducer from "./data-project-reducer/data-project-reducer";

export const rootReducer = combineReducers({
    home: homeReducer,
    gui:guiReducer,
    projectEditor:projectEditorReducer,
    featured: featuredReducer,
    listProject: listProjectReducer,
    profile: profileReducer,
    register: registerReducer,
    registerSource: RegisterSourceReducer,
    forgot: forgotpassReducer,
    tuto:  tutorialsReducer,
    loadingBar: loadingBarReducer,
    auth: authReducer,
    dataProjectJson:dataProjectReducer,
    contest:contestReducer
  });

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'registerSource']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const myMiddleware=(store)=>(next)=>(action)=>{
  //toDo
  return next(action);
}
 
export const store = createStore(persistedReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), applyMiddleware(myMiddleware,thunk));
export const persistor = persistStore(store);
