import {
    GET_PROFILE_BY_ID_REQUEST, GET_PROFILE_BY_ID_SUCCESS, GET_PROFILE_BY_ID_FAIL,
    TO_UPDATE_AVATAR, TO_UPDATE_INFO, BACK_TO_USER_HOMEPAGE, 
    UPDATE_PROFILE_AVATAR_REQUEST, UPDATE_PROFILE_AVATAR_SUCCESS, UPDATE_PROFILE_AVATAR_ERROR,
    UPDATE_PROFILE_INFO_REQUEST, UPDATE_PROFILE_INFO_SUCCESS, UPDATE_PROFILE_INFO_ERROR,
    GET_DVHC
} from "../constants/constants";

const initialState = {
    authorId: null,
    authorUniqCode: "",
    loading: false,
    isUpdatingInfo: false,
    isUpdatingAvatar: false,
    waitForUpdateAvatar: false,
    waitForUpdateInfo: false,
    dvhc: null,
    projectsAuthorName: "",
    projectsAuthorAvatar: "",
    error: "",
    updateAvatarError: "",
    updateInfoError: "",
    bio:"",
    country: "",
    district: "",
    province: "",
    school: null,
    birthday:""
}

const profileReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_DVHC:
            return {
                ...state,
                dvhc: action.data.dvhc
            };
        case GET_PROFILE_BY_ID_REQUEST:
            return {
                ...state,
                error: "",
                loading: true,
                isUpdatingInfo: false,
                isUpdatingAvatar: false,
                authorId: null,
                authorUniqCode: ""
            };
        case GET_PROFILE_BY_ID_SUCCESS:
            return {
                ...state,
                authorId: action.data.authorId,
                authorUniqCode: action.data.authorUniqCode,
                projectsAuthorName: action.data.projectsAuthorName,
                projectsAuthorAvatar: action.data.projectsAuthorAvatar,
                bio: action.data.bio,
                country: action.data.country,
                province: action.data.province,
                district: action.data.district,
                school: action.data.school,
                birthday: action.data.birthday,
                loading: false,
                error: ""
            }
        case GET_PROFILE_BY_ID_FAIL:
            return {
                ...state,
                loading: false,
                error: action.data.error
            }
        case TO_UPDATE_AVATAR:
            return {
                ...state,
                updateAvatarError: "",
                isUpdatingAvatar: true,
                isUpdatingInfo: false
            }
        case TO_UPDATE_INFO:
            return {
                ...state,
                updateInfoError: "",
                isUpdatingInfo: true,
                isUpdatingAvatar: false,
            }
        case BACK_TO_USER_HOMEPAGE:
            return {
                ...state,
                isUpdatingAvatar:false,
                isUpdatingInfo: false,
                waitForUpdateAvatar:false,
                error: "",
                updateAvatarError: ""
            }
        case UPDATE_PROFILE_AVATAR_REQUEST:
            return {
                ...state,
                updateAvatarError: "",
                waitForUpdateAvatar: true,
            }
        case UPDATE_PROFILE_AVATAR_SUCCESS:
            return {
                ...state,
                waitForUpdateAvatar: false,
                projectsAuthorAvatar: action.data.avatar,
                updateAvatarError: ""
            }
        case UPDATE_PROFILE_AVATAR_ERROR:
            return {
                ...state,
                waitForUpdateAvatar: false,
                updateAvatarError: action.data.error
            }
        case UPDATE_PROFILE_INFO_REQUEST:
            return {
                ...state,
                waitForUpdateInfo: true,
                updateInfoError: "",
            }
        case UPDATE_PROFILE_INFO_SUCCESS:
            return {
                ...state,
                waitForUpdateInfo: false,
                updateInfoError: ""
            }
        case UPDATE_PROFILE_INFO_ERROR:
            return {
                ...state,
                waitForUpdateInfo: false,
                updateInfoError: action.data.error
            }
        default:
            return state;
    }

}

export default profileReducer;