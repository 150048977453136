import React, { Component } from 'react';
import AuthAPI from '../../api/auth-api';
import { Link, withRouter } from "react-router-dom";
import { getUserProfile,updateAuthInEditor } from "../../reducers/codekitten/auth-reducer/auth-actions.js";
import { store } from "../../reducers/codekitten/store";
import { connect } from 'react-redux';
import RequestButton from '../../components/codekitten/request-btn/request-btn.jsx';
import PropTypes from 'prop-types';
import { dispatchSaveFileToServer } from '../../reducers/codekitten/gui-reducer/gui-actions';
import SeoComponent from '../../components/codekitten/seo/seo-component.jsx';
import { setRegisterSource } from '../../reducers/codekitten/register-reducer/register-source-actions.js';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            error: "",
            activePW: false,
            waitingForLogin: false,
            isPopup: this.props.isPopup || false
        };
        this.toggleInputType = this.toggleInputType.bind(this);
        this.loginRequest = this.loginRequest.bind(this);
    };

    toggleInputType() {
        this.setState({
            activePW: !this.state.activePW
        })
    }
    checkPhonenumber(e) {
        let value = e.target.value;
        var clearedNumberStr = "";
        if (value.length > 50) value = value.substring(0, 50);
        for (var i = 0; i < value.length; i++) {
            if (!isNaN(value.charAt(i)))
                clearedNumberStr += value.charAt(i);
        }

        if (clearedNumberStr.length > 12) {
            e.target.value = clearedNumberStr.substring(0, 12);
        } else {
            e.target.value = clearedNumberStr;
        }
        return;
    };

    onChangePW(e) {
        let password = e.target.value;
        password = password.replace(/ /g, '');
        e.target.value = password;
    }

    loginRequest() {
        const phonenumberField = document.getElementById("phonenumberInputField");
        const passwordField = document.getElementById("passwordInputField");
        let phonenumber = phonenumberField.value;
        const password = passwordField.value;
        if (phonenumber === "" || password === "") {
            this.setState({
                error: "Vui lòng nhập đầy đủ thông tin!"
            })
            return;
        }
        this.setState({ waitingForLogin: true });


        if (phonenumber.charAt(0) === '0') phonenumber = phonenumber.substring(1, phonenumber.length);

        const data = {
            client_id: process.env.CLIENT_ID,
            client_secret: process.env.CLIENT_SECRET,
            username: "+84" + phonenumber,
            password: password,
            grant_type: "password",
        };
        let _this = this;
        AuthAPI.login(data).then((response) => {

            const status_code = response.message.status_code;
            if (status_code == 200) {
                this.setState({ error: "" })
                const returnData = response.data;
                //lưu file khi chưa đăng nhập
                var that=this
                if (this.state.isPopup) {
                    
                  console.log(this);
                    that.props.dispatchSaveFileToServer(returnData.access_token);
                    that.props.updateAuthInEditor(true);
                }
                store.dispatch(getUserProfile(returnData.access_token));

                // this.props.getUserProfile(returnData.access_token);
            } else {
                this.setState({ waitingForLogin: false, error: "Số điện thoại mặc mật khẩu không đúng!" });
            }
        }).catch(error => {
            this.setState({ error: error, waitingForLogin: false })
        })
    }
    componentDidMount() {
        if (this.props.loggedIn && !this.state.isPopup) {
            this.props.history.push("/");
        }

        const QueryParams = new URLSearchParams(this.props.location.search); 
        const utm_source = QueryParams.get('utm_source');
        if(typeof utm_source !== "undefined" && utm_source !== "" && utm_source !== null) {
            this.props.setRegisterSource(utm_source);
        }
    }
    componentDidUpdate() {
        if (this.props.loggedIn && !this.state.isPopup) {
            this.props.history.push("/");
        }
    }

    render() {
        return (
            <>
                <SeoComponent title="Đăng nhập"/>
                {!this.state.isPopup && <div className="bg-user-full" />}
                <div className="c-auth-center">
                    <div className="c-auth-box">
                        <div className="c-auth-box__left">
                            <div className="c-auth-box__logo">
                                <Link to='/'>
                                    <img src="/static/images/logo-black.svg" alt="logo" />
                                </Link>
                            </div>
                            <div className="c-auth-form">
                                <div className="form-group">
                                    <input id="phonenumberInputField" className="form-control" type="text" placeholder="Số điện thoại" onChange={event => this.checkPhonenumber(event)} autoFocus />
                                </div>
                                <div className="form-group form-group-icons">
                                    <input id="passwordInputField" className="form-control" type={this.state.activePW ? "text" : "password"} placeholder="Mật khẩu" onChange={e => this.onChangePW(e)} /><span className={`form-group-icon js-show-password ${this.state.activePW ? "active" : ""}`} id="eye-span" onClick={this.toggleInputType} ><i className="icon16-eye-black" /></span>
                                </div>
                                <div className="text-danger">{this.state.error}</div>
                                <div className="form-group is-button">
                                    <RequestButton waitingForResponse={this.state.waitingForLogin} onClick={this.loginRequest} content="Đăng nhập"></RequestButton>
                                </div>

                                {!this.state.isPopup && <div className="form-group text-right">
                                    <p className="is-small">
                                        Bạn không nhớ&nbsp;
                                        <Link to="/forgotpassword">mật khẩu?</Link>
                                    </p>
                                </div>}
                                {!this.state.isPopup && <div className="form-group text-right is-padding">
                                    <p>
                                        Bạn chưa có tài khoản?&nbsp;
                                        <Link to="/register">Đăng ký ngay</Link>
                                    </p>
                                </div>}
                            </div>
                            <div className="c-auth-box__copyright">
                                Made with&nbsp;
                                <i className="icon16-heart-red" />
                                &nbsp;by TEKY
                            </div>
                        </div>
                        <div className="c-auth-box__right bg-primary">
                            <div className="c-auth-box__info">
                                <h1>Đăng nhập!...</h1>
                                <p>Chào mừng bạn trờ lại với chúng tôi</p>
                            </div>
                            <div className="c-auth-box__img"><img src="/static/images/kitten-auth.png" alt="kitten" /></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

Login.propTypes = {
    isPopup: PropTypes.bool

};
const mapStateToProps = (state) => {
    return {
        loggedIn: state.auth.loggedIn
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: (token) => dispatch(getUserProfile(token)),
        //thực hiện khi đang ở trong trang editor
        dispatchSaveFileToServer: (token) => dispatch(dispatchSaveFileToServer(token)),
        updateAuthInEditor:(isAuthInEditor)=> dispatch(updateAuthInEditor(isAuthInEditor)),
        setRegisterSource: (utm_source) => dispatch(setRegisterSource(utm_source))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));