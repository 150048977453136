exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".progress-bar_progress-div_3RrcY {\n    background-color: rgb(233, 233, 233);\n    border-radius: 0.4rem;\n  }\n  \n  .progress-bar_progress_o_rf0 {\n    background: rgb(248, 164, 9);\n    background: -webkit-gradient(\n      linear,\n      left top, right top,\n      from(rgba(24, 204, 147, 1)),\n      color-stop(52%, rgba(24, 204, 147, 0.6264880952380952)),\n      to(rgba(24, 204, 147, 0.4332107843137255))\n    );\n    background: -o-linear-gradient(\n      left,\n      rgba(24, 204, 147, 1) 0%,\n      rgba(24, 204, 147, 0.6264880952380952) 52%,\n      rgba(24, 204, 147, 0.4332107843137255) 100%\n    );\n    background: linear-gradient(\n      90deg,\n      rgba(24, 204, 147, 1) 0%,\n      rgba(24, 204, 147, 0.6264880952380952) 52%,\n      rgba(24, 204, 147, 0.4332107843137255) 100%\n    );\n  \n    height: 10px;\n    -webkit-transition: 1s ease;\n    -o-transition: 1s ease;\n    transition: 1s ease;\n    -webkit-transition-delay: 0.5s;\n         -o-transition-delay: 0.5s;\n            transition-delay: 0.5s;\n    border-radius: 0.4rem;\n    margin: 0;\n  }\n  \n  .progress-bar_progressComp_rsgNH h1 {\n    font-size: 10px;\n  }\n  \n  .progress-bar_percent-number_UFubP {\n    color: rgb(121, 121, 121);\n  }", ""]);

// exports
exports.locals = {
	"progress-div": "progress-bar_progress-div_3RrcY",
	"progressDiv": "progress-bar_progress-div_3RrcY",
	"progress": "progress-bar_progress_o_rf0",
	"progressComp": "progress-bar_progressComp_rsgNH",
	"percent-number": "progress-bar_percent-number_UFubP",
	"percentNumber": "progress-bar_percent-number_UFubP"
};