import * as React from "react";
import styles from "./progress-bar.css";
export var ProgressBar = ({ width, percent }) => {

    const [value, setValue] = React.useState(0);
  
    React.useEffect(() => {
      setValue(percent * width);
    });
  
    return (
      <div>
        <div  style={{ width: width }}>
          <div style={{ width: `${percent}px` }} className={styles.progress} />
          <div>Loading.... {percent} </div>
        </div>
      </div>
    );
  };