import {
    LIST_PROJECTS_REQUEST, LIST_PROJECTS_SUCCESS, LIST_PROJECTS_ERROR, SET_CURRENT_PAGE,SET_TITLE_LIST_PROJECT,RESET_STATE_LIST_PROJECT
} from '../constants/constants';

const initialState = {
    totalPage: 1,
    loadingProjects: true,
    projects: [],
    errorProjects: '',
    currentPage: 1,
    title: ''



}

const listProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_PROJECTS_REQUEST:
            return {
                ...state,
             
                loadingProjects: state.projects.length>0?false:true
            };
        case LIST_PROJECTS_SUCCESS: {
            return {
                ...state,
                loadingProjects:false,
                projects: state.projects.concat(action.data.projects),
                totalPage: action.data.totalPage,
                errorProjects: ''
               
            };
        }
        case LIST_PROJECTS_ERROR: {
            return {
                ...state,
                loadingProjects: false,
              
                errorProjects: action.data.errorProjects
            }
        }
        case SET_CURRENT_PAGE: {
            return {
                ...state,
                currentPage: action.data.currentPage
            }
        }
        case SET_TITLE_LIST_PROJECT:{
            return {
                ...state,
                title:action.data.title
            }
        }
        case RESET_STATE_LIST_PROJECT:{
            return {
                ...state,
                currentPage:1,
                projects: []
            }
        }
        default:
            return state;
    }
};
export default listProjectReducer;