import {
    GET_PROFILE_BY_ID_REQUEST, GET_PROFILE_BY_ID_SUCCESS, GET_PROFILE_BY_ID_FAIL,
    TO_UPDATE_AVATAR, TO_UPDATE_INFO, BACK_TO_USER_HOMEPAGE,
    UPDATE_PROFILE_AVATAR_REQUEST, UPDATE_PROFILE_AVATAR_SUCCESS, UPDATE_PROFILE_AVATAR_ERROR,
    UPDATE_PROFILE_INFO_REQUEST, UPDATE_PROFILE_INFO_SUCCESS, UPDATE_PROFILE_INFO_ERROR,
    GET_DVHC
} from "../constants/constants";

import { updateLocalUserAvatar, getUserProfile } from "../auth-reducer/auth-actions.js";
import authApi  from "../../../api/auth-api.js";
import {store} from "../store"
import profileApi from "../../../api/profile-api";

export const getDVHC = () => (dispatch) => {
    profileApi.getDVHC().then((response) => {
        dispatch(saveDVHCToState(response.data))
    })
}

export const getUserProfileByRandomCode = (userId) => (dispatch) => {
    dispatch(getProfileRequest());
    authApi.getProfileByUserCode(userId).then((response) => {
        const status = response.message.status;
        const data = response.data;
        if (status === "Success") {
            dispatch(getProfileRequestSuccess(userId, data.uniq_random_code, data.full_name, data.avatar, data.bio, data.country, data.province, data.district, data.date_birthday, data.school));
        } else if (status === "Fail") {
            // this.props.history.push("/undified");
            dispatch(getProfileRequestError("Get profile error!"))
        }

    }, (error) => {
        console.log(error);
        dispatch(getProfileRequestError("Get profile error!"))
    })
}

export const updateUserInfo = (data) => (dispatch) => {
    const auth = store.getState().auth;
    dispatch(updateAuthorInfoRequest())
    profileApi.updateProfile(auth.token, auth.id, data.fullname, data.status, data.bio, data.country, data.province, data.district, data.school, data.birthday).then(response => {
        if(response.message.status_code == 200) {
            dispatch(updateAuthorInfoSuccess())
            dispatch(getUserProfileByRandomCode(auth.uniq_random_code))
            dispatch(getUserProfile(auth.token)) // update current user profile
            dispatch(backToHomepage())
        } else {
            dispatch(updateAuthorInfoFail())
        }
    }).catch(error => {
        dispatch(updateAuthorInfoFail())
    })
}

export const updateUserAvatar = (formData) => (dispatch) => {
    const auth = store.getState().auth;
    
    dispatch(updateAuthorAvatarRequest())
    profileApi.updateAvatar(auth.token, auth.id, formData).then(response=> {
        if(response.message.status_code == 200) {
            dispatch(updateAuthorAvatar(response.data.avatar))
            dispatch(updateLocalUserAvatar(response.data.avatar))
            dispatch(backToHomepage())
        } else dispatch(updateAuthorAvatarFail("update avatar invalid!"));
    }).catch( error => {
        console.log("Lỗi gọi api update avatar")
        dispatch(updateAuthorAvatarFail("update avatar failed!"))
    })
}

export const toUpdateAvatar = () => ({
    type:TO_UPDATE_AVATAR
})

export const toUpdateInfo = () => ({
    type: TO_UPDATE_INFO
})

export const backToHomepage = () => ({
    type: BACK_TO_USER_HOMEPAGE
})

export const getProfileRequest = () => ({
    type: GET_PROFILE_BY_ID_REQUEST,
})

const getProfileRequestSuccess = (authorId, authorUniqCode, projectsAuthorName, projectsAuthorAvatar, bio, country, province, district, birthday, school) => ({
    type: GET_PROFILE_BY_ID_SUCCESS,
    data: {
        authorId: authorId,
        authorUniqCode: authorUniqCode,
        projectsAuthorName: projectsAuthorName,
        projectsAuthorAvatar: projectsAuthorAvatar,
        bio: bio,
        province: province,
        district: district,
        country: country,
        birthday: birthday,
        school: school
    }
})

const getProfileRequestError = (error) => ({
    type: GET_PROFILE_BY_ID_FAIL,
    data: {
        error: error
    }
})

const updateAuthorAvatarRequest = () => ({
    type: UPDATE_PROFILE_AVATAR_REQUEST
})

const updateAuthorAvatar = (avatar) => ({
    type: UPDATE_PROFILE_AVATAR_SUCCESS,
    data: {avatar: avatar}
})

const updateAuthorAvatarFail = (error) => ({
    type: UPDATE_PROFILE_AVATAR_ERROR,
    data: {error: error}
})

const updateAuthorInfoRequest = () => ({
    type:UPDATE_PROFILE_INFO_REQUEST
})

const updateAuthorInfoSuccess = () => ({
    type: UPDATE_PROFILE_INFO_SUCCESS
})

const updateAuthorInfoFail = () => ({
    type: UPDATE_PROFILE_INFO_ERROR,
    data: {error: "update profile failed!"}
})

const saveDVHCToState = (dvhc) => ({
    type: GET_DVHC,
    data: {dvhc:dvhc}
})