import { SAVE_REGISTER_SOURCE, EXPIRE_REGISTER_SOURCE } from "../constants/constants";
const initialState = {
    utm_source: "",
    savedTime: null,
}

const RegisterSourceReducer = (state=initialState, action) => {
    switch (action.type) {
        case SAVE_REGISTER_SOURCE:
            return {
                utm_source: action.data.utm_source,
                savedTime: action.data.savedTime,
            }
        case EXPIRE_REGISTER_SOURCE:
            return {
                utm_source: "",
                savedTime: null
            }
        default:
            return state
    }

}
export default RegisterSourceReducer;