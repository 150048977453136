import React from 'react'
import { Component } from 'react'
import PropTypes from 'prop-types';
import StepBarComponent from '../step-bar-component/step-bar-component.jsx'
import OTPVerifyForm from './auth-otp-verify-form.jsx'
import RegisterInfoForm from './auth-register-info.jsx'
// import { useState } from 'react'
import RegisterPWForm from './auth-register-pw-form.jsx'
import UpdateInfoForm from './auth-update-info-form.jsx'
import UpdateAvtForm from './auth-update-avt-form.jsx'
import { Transition, animated } from 'react-spring'
import { registerFirstStep, registerSecondStep, registerLastStep, changeUserRegisterInfo, updateRegisterInfo, updateRegisterAvt, clearRegisterState } from "../../../reducers/codekitten/register-reducer/register-actions.js";
import { getDVHC } from '../../../reducers/codekitten/profile-reducer/userprofile-actions.js'
import { connect } from 'react-redux'
import {  getUserProfile } from "../../../reducers/codekitten/auth-reducer/auth-actions.js";
import toast, { Toaster } from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import { store } from '../../../reducers/codekitten/store.js'
import { dispatchSaveFileToServer } from '../../../reducers/codekitten/gui-reducer/gui-actions';
const steps = ["Xác minh số điện thoại", "Tạo mật khẩu", "Thông tin", "Hoàn thành"];
class RegisterFormContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isRegistered: false
        }
    }

    componentDidMount() {
        window.scroll(0, 0)
        this.props.clearRegisterState()
        toast.dismiss()
       
    }
    componentWillUnmount() {
      
    }

    componentDidUpdate() {
        if (!this.state.isRegistered && this.props.registerSuccess) {

            toast.dismiss()
            toast.success("Đăng ký tài khoản thành công!", { duration: 5000 })
            this.setState({ isRegistered: true })
        }
        if (this.props.completedAllStep && this.props.isInEditor) {
            this.reDiectCompelete();
        }
    }
    submitFirstForm = (fullname, phonenumber) => {
        this.props.registerFirstStep(fullname, phonenumber);
    }

    submitSecondForm = (otp) => {
        this.props.registerSecondStep(otp, this.props.phonenumber);
    }

    resendOTP = () => {
        this.props.registerFirstStep(this.props.fullname, this.props.phonenumber);
    }

    changeUserRegisterInfo = () => {
        this.props.changeUserRegisterInfo()
    }

    submitThirdForm = (password) => {
        this.props.registerLastStep(this.props.registrationCode, password)
    }

    updateUserInfo = (provinceCode, districtCode, schoolCode) => {

        this.props.updateRegisterInfo(provinceCode, districtCode, schoolCode, this.props.auth)
    }
    // goNextStep =  () => {this.setState({ activeStep: this.state.activeStep + 1 })}
    updateUserAvt = (formData) => {
        this.props.updateRegisterAvt(formData, this.props.auth)

    }
    reDiectCompelete = () => {


        if (this.props.isInEditor) {
            if (this.props.auth.token !== '') {
                this.props.dispatchSaveFileToServer(this.props.auth.token)
                store.dispatch(getUserProfile(this.props.auth.token));
            }
        } else {
            this.props.history.replace('/');
        }
    }

    render() {
        const { onStep, fullname, phonenumber, registerSuccess, dvhc, loggedIn, error, waitingForResponse, completedAllStep } = this.props;
       const activeStep = onStep - 2
        //  const activeStep = 3
        return (
            <>
                <Toaster position="top-center" />
                {activeStep != -1 ? <StepBarComponent isInEditor={this.props.isInEditor} activeStep={activeStep} steps={steps} /> : <div style={{ padding: `${this.props.isInEditor ? '' : '50px 0px'}` }}></div>}
                {activeStep == -1 ? <RegisterInfoForm isInEditor={this.props.isInEditor} defaultFullname={fullname} defaultPhonenumber={phonenumber}
                    submitForm={this.submitFirstForm} error={error} loading={waitingForResponse} /> : null}
                {activeStep == 0 ? <OTPVerifyForm phonenumber={phonenumber} resendOTP={this.resendOTP}
                    submitForm={this.submitSecondForm} goBack={this.changeUserRegisterInfo} error={error} loading={waitingForResponse} /> : null}
                {activeStep == 1 ? <RegisterPWForm isInEditor={this.props.isInEditor} submitForm={this.submitThirdForm} registerSuccess={registerSuccess}
                    error={error} loading={waitingForResponse} /> : null}
                {activeStep == 2 ? <UpdateInfoForm submitForm={this.updateUserInfo}
                    loggedIn={loggedIn} getDVHC={this.props.getDVHC} dvhc={dvhc} error={error} loading={waitingForResponse} /> : null}
                {activeStep == 3 ? <UpdateAvtForm updateUserAvatar={this.updateUserAvt} onSkip={this.reDiectCompelete}
                    fullname={fullname} error={error} loading={waitingForResponse} completedAllStep={completedAllStep} /> : null}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        onStep: state.register.onStep,
        completedAllStep: state.register.completedAllStep,
        countryCode: state.register.countryCode,
        phonenumber: state.register.phonenumber,
        fullname: state.register.fullname,
        registrationCode: state.register.registrationCode,
        waitingForResponse: state.register.waitingForResponse,
        error: state.register.error,
        registerSuccess: state.register.registerSuccess,
        token: state.register.token,
        auth: state.auth,
        loggedIn: state.auth.loggedIn,
        isRegisterInEditor: state.auth.isRegisterInEditor,

        dvhc: state.profile.dvhc
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        registerFirstStep: (fullname, phonenumber) => dispatch(registerFirstStep(fullname, "+84", phonenumber)),
        changeUserRegisterInfo: () => dispatch(changeUserRegisterInfo()),
        registerSecondStep: (otp, phonenumber) => dispatch(registerSecondStep(otp, "+84", phonenumber)),
        registerLastStep: (registrationCode, password) => dispatch(registerLastStep(registrationCode, password)),
        getDVHC: () => dispatch(getDVHC()),
        updateRegisterInfo: (provinceCode, districtCode, schoolCode, auth) => dispatch(updateRegisterInfo(provinceCode, districtCode, schoolCode, auth)),
        updateRegisterAvt: (formData, auth) => dispatch(updateRegisterAvt(formData, auth)),
        clearRegisterState: () => dispatch(clearRegisterState()),
        //in editor
        dispatchSaveFileToServer: (token) => dispatch(dispatchSaveFileToServer(token)),
    };
};
RegisterFormContainer.defaultProps = {
    isInEditor: false
}
RegisterFormContainer.propTypes = {
    isInEditor: PropTypes.bool
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterFormContainer));