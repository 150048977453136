import React, { Component } from 'react'
import Loader from 'react-loader-spinner';
export default class RequestButton extends Component {
    render() {
        return (
            <>
                {this.props.waitingForResponse ?<Loader className="TailSpin" type="Rings" color="#ffa31a" height={100} width={100}/> :
                <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={this.props.onClick} ><span>{this.props.content}</span>
                <i className="icon36-arrow-long-right-white" /></button>}
            </>
        )
    }
}
