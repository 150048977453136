import { GET_ACTIVE_CONTESTS_REQUEST, GET_ACTIVE_CONTESTS_SUCCESS, GET_ACTIVE_CONTESTS_FAIL } from "../constants/constants"
const initialState = {
    activeContests: [],
    loadingActiveContests: true
}

const contestReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_ACTIVE_CONTESTS_REQUEST: 
            return {
                ...state,
                activeContests: [],
                loadingActiveContests: true
            }
        case GET_ACTIVE_CONTESTS_SUCCESS:
            return {
                ...state,
                activeContests: action.data.contests,
                loadingActiveContests: false
            }
        case GET_ACTIVE_CONTESTS_FAIL:
            return {
                ...state,
                activeContests: [],
                loadingActiveContests: false
            }
        default:
            return state
    }
}

export default contestReducer;