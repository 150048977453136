import axiosClient from "./axios-client";
const profileApi = {
    updateAvatar: (token, id, formData) => {
        const url = `/api/v1/users/${id}/avatar`;
        return axiosClient.post(url, formData, 
        {
            headers: {
                'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
                "Authorization": `Bearer ${token}`,

            }
        })
    },
    updateProfile: (token, id, full_name, status, bio, country, province, district, school, birthday) => {
        const url = `/api/v1/users/${id}/profile`;
        console.log("fullname " + full_name)
        return axiosClient.put(url, {
            full_name: full_name,
            status: status,
            bio: bio,
            // country: country,
            province: province,
            school: school,
            district: district,
            date_birthday: birthday
        },
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
    },
    getDVHC: () => {
        const url = "/api/v1/users/province_district";
        return axiosClient.get(url)
    },
    getSchoolsByDistrictCode: (district_code) => {
        const url = `/api/v1/users/school?district_code=${district_code}`;
        return axiosClient.get(url)
    }
}

export default profileApi;