import { SAVE_REGISTER_SOURCE, EXPIRE_REGISTER_SOURCE } from "../constants/constants";
import {store} from "../store"

export const setRegisterSource = (utm_source) => (dispatch) => {
    const d = new Date()
    let savedTime = d.getTime()
    dispatch({
        type: SAVE_REGISTER_SOURCE,
        data: {
            utm_source,
            savedTime
        }
    })
}

export const expireRegisterSource = () => (dispatch) => {
    const register_source = store.getState().registerSource;
    let savedTime = register_source.savedTime;
    let currentTime = new Date().getTime();
    if((currentTime - savedTime)/(1000*60) > 24) {
        dispatch({
            type: EXPIRE_REGISTER_SOURCE
        })
    }
}