import React from 'react'
import { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import LoginForm from './login-form.jsx';
import { getUserProfile,updateAuthInEditor } from "../../../reducers/codekitten/auth-reducer/auth-actions.js";
import { dispatchSaveFileToServer } from '../../../reducers/codekitten/gui-reducer/gui-actions';
import AuthAPI from '../../../api/auth-api';
import { store } from "../../../reducers/codekitten/store";
class LoginFormContainter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            waitingForLogin: false,
            error: ""
        }
    }

    componentDidMount() {
        window.scroll(0, 0)

       
    }

    loginRequest = (phonenumber, password) => {
        if (phonenumber.charAt(0) === '0') phonenumber = phonenumber.substring(1, phonenumber.length);

        const data = {
            client_id: process.env.CLIENT_ID,
            client_secret: process.env.CLIENT_SECRET,
            username: "+84" + phonenumber,
            password: password,
            grant_type: "password",
        };

        this.setState({waitingForLogin: true, error: ""})
        AuthAPI.login(data).then((response) => {
            const status_code = response.message.status_code;
            if (status_code == 200) {
                const returnData = response.data;
                console.log("login access token: ", returnData.access_token)
                var that=this
                if (this.props.isInEditor) {
                    that.props.dispatchSaveFileToServer(returnData.access_token);
                    that.props.updateAuthInEditor(true);
                }
                store.dispatch(getUserProfile(returnData.access_token));
                this.setState({error: "" })
            } else {
                this.setState({ waitingForLogin: false, error: "Số điện thoại hoặc mật khẩu không chính xác!" });
            }
        }).catch(error => {
            console.log(error)
            this.setState({ error: "Đã có lỗi xảy ra vui lòng thử lại sau", waitingForLogin: false })
        })
    }

    componentDidUpdate() {
        if (this.props.loggedIn&&!this.props.isInEditor) {
            this.props.history.push("/");
        }
    }

    render() {
        const {waitingForLogin, error} = this.state;
        return (
            <>
                <div style={{ padding: `${this.props.isInEditor ? '' : '50px 0px'}` }}></div>
                <LoginForm loading={waitingForLogin} error={error} submitForm={this.loginRequest} isInEditor={this.props.isInEditor}/>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        loggedIn: state.auth.loggedIn
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: (access_token) => dispatch(getUserProfile(access_token)),
        dispatchSaveFileToServer: (token) => dispatch(dispatchSaveFileToServer(token)),
        updateAuthInEditor:(isAuthInEditor)=> dispatch(updateAuthInEditor(isAuthInEditor)),
    };
};
LoginFormContainter.defaultProps = {
    isInEditor: false
}
LoginFormContainter.propTypes = {
    isInEditor: PropTypes.bool
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginFormContainter));