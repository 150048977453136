exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".submit-form-btn-component_button_2458i {\n    margin: auto!important;\n    text-transform: none!important;\n    outline: none!important;\n    color: white!important;\n    width: 160px;\n    font-size: 20px!important;\n    text-align: center;\n    padding-left: auto;\n    padding-right: auto;\n    background-color: rgba(255, 123, 28, 0.87)!important;\n    border-radius: 18px!important;\n}\n\n.submit-form-btn-component_loading_1BIYm {\n    margin: auto!important;\n    padding: auto!important;\n}", ""]);

// exports
exports.locals = {
	"button": "submit-form-btn-component_button_2458i",
	"loading": "submit-form-btn-component_loading_1BIYm"
};