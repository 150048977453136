exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".register-info_backToHomepage_ZJKlS {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    padding-left: 6%;\n    padding-right: 6%;\n    font-size: 20px;\n    color: black!important;\n    margin-bottom: 25px!important;\n    -webkit-box-align: center!important;\n    -webkit-align-items: center!important;\n        -ms-flex-align: center!important;\n            align-items: center!important;\n}\n\n.register-info_form_3L_QJ {\n    width: 90%;\n    max-width:500px;\n    padding-top: 25px!important;\n    padding-bottom: 25px!important;\n}\n\n@media only screen and (max-width: 960.5px) {\n    .register-info_form_3L_QJ {\n    margin: auto!important;\n    }\n}\n\n.register-info_registerInfoGrid_nnWKp {\n    padding-top: 150px;\n    padding-bottom: 150px;\n}\n\n.register-info_kitten_1nWil {\n    width: 80%;\n    max-width:575px;\n    max-height: 537px;\n    float: right;\n    margin-right: 100px;\n}\n\n.register-info_formTitle_2sDKP {\n    text-align: center;\n    font-size: 28px;\n    font-weight: 600!important;\n}\n\n.register-info_TextField_3x7Ve {\n    width: 100%;\n    margin-top: 10px!important;\n    margin-bottom: 15px!important;\n    background-color: #F1F1F1;\n    border-radius: 13px!important;\n}\n\n.register-info_formContent_3acd6 {\n    padding-right: 6%!important;\n    padding-left: 6%!important;\n}\n\n.register-info_formContent_3acd6 fieldset {\n    border-radius: 13px!important;\n    padding-left: 17px;\n}\n\n.register-info_formContent_3acd6 label {\n    padding-left: 10px;\n}\n\n.register-info_error_H4WwI {\n    color: red;\n    font-size: 16px;\n    margin: 0!important;\n}\n\n.register-info_linkToLogin_1I7Kv {\n    width: 100%;\n    padding-right: 6%;\n    padding-left: 6%;\n    text-align: center!important; \n    font-size: 20px!important;\n}\n\n.register-info_linkToLogin_1I7Kv a {\n    color: #5bc0a6!important;\n    font-weight: bold;\n}", ""]);

// exports
exports.locals = {
	"backToHomepage": "register-info_backToHomepage_ZJKlS",
	"form": "register-info_form_3L_QJ",
	"registerInfoGrid": "register-info_registerInfoGrid_nnWKp",
	"kitten": "register-info_kitten_1nWil",
	"formTitle": "register-info_formTitle_2sDKP",
	"TextField": "register-info_TextField_3x7Ve",
	"textField": "register-info_TextField_3x7Ve",
	"formContent": "register-info_formContent_3acd6",
	"error": "register-info_error_H4WwI",
	"linkToLogin": "register-info_linkToLogin_1I7Kv"
};