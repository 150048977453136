import { GET_PROJECT_DATA_FILE_REQUEST, GET_PROJECT_DATA_FILE_SUCCESS, GET_PROJECT_DATA_FILE_FAIL } from "../constants/constants";

const initialState = {
    projectJson: null,
    error: ''
};

const dataProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECT_DATA_FILE_REQUEST:
            return {
                ...state
            };
        case GET_PROJECT_DATA_FILE_SUCCESS:
            return {
                ...state,
                error:'',
                projectJson: action.data.projectJson
            }
        case GET_PROJECT_DATA_FILE_FAIL:
            return {
                ...state,
                error: action.data.error
            }

        default:
           return state;
    }
}
export default dataProjectReducer;
