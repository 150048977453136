import React from 'react'
import { Box, Card, CardActions, CardContent } from '@material-ui/core'
import styles from './forms-styles.css'
import { useState, useEffect } from 'react'
import SubmitFormBtn from '../submit-form-btn-component/submit-form-btn-component.jsx'
import OtpInput from 'react-otp-input';
import toast, { Toaster } from 'react-hot-toast';
import ArrowBackIcon from '@material-ui/icons//ArrowBack';
import Timer from '../countdownTimer/countdown-timer.jsx'
const appearAnimation = {
    transition: 'all 300ms',
    transitionDelay: '300ms'
}

export default function OTPVerifyForm({phonenumber = "", submitForm, goBack, resendOTP, error, loading}) {
    const [OTP, setOTP] = useState("")
    const [showAlert, setshowAlert] = useState(false);
    const [EndUpCountdown, setEndUpCountdown] = useState(false)
    const [APIerror, setAPIerror] = useState("");
    const [Opacity, setOpacity] = useState(0);
    const [left, setLeft] = useState('-100px');

    useEffect(() => {
        setOpacity(1);
        setLeft(0)
        toast.dismiss()
    }, [])

    useEffect(() => {
        if(error !== "") {
            setAPIerror(error)
        }
    }, [error])
    const resendCode = () => {
        if(EndUpCountdown) {
            resendOTP()
            setEndUpCountdown(false)
            setOTP("")
        }
    }
    const submit = (e) => {
        e.preventDefault()
        if(OTP.length < 6) {
            setshowAlert(true)
        } else {
            setshowAlert(false)
            submitForm(OTP);
        }
    }
    return (
        <div style={{...appearAnimation, position: 'relative', opacity: Opacity, left: left,}}>
        <Box className={styles.box}>
            <Toaster position="top-center"/>
            <Card className={styles.form}>
                <ArrowBackIcon className={styles.backArrow} fontSize="large" onClick={()=> goBack()}/>
                <h2 className={styles.formTitle}>Vui lòng nhập mã xác minh</h2>
                <CardContent>
                    <p className={styles.formDes}>
                        Mã xác minh của bạn sẽ được gửi bằng tin nhắn đến<br/>
                        (+84) {phonenumber}
                    </p>
                    <form onSubmit={submit}>
                    <OtpInput
                        value={OTP}
                        shouldAutoFocus={1}
                        containerStyle={styles.OtpInput}
                        inputStyle={styles.inputOtpStyle}
                        onChange={otp => {setOTP(otp); setAPIerror("")}}
                        numInputs={6}
                        separator={<span></span>}
                    />
                    <input type={'submit'} style={{display: 'none'}}/>
                    </form>
                <p className={styles.error + " " + styles.OTPerror}>
                {showAlert? <p>Vui lòng nhập đầy đủ mã</p> : null}
                <p>{APIerror}</p>
                </p>
                <p className={styles.resendCode}>Bạn không nhận được mã? {!EndUpCountdown ? <Timer initialMinute={2} EndUpCountdown={() => setEndUpCountdown(true)}/> : null} <span className={!EndUpCountdown ? styles.disabled : ""} onClick={resendCode}>Gửi lại</span></p>
                </CardContent>
                <CardActions>
                <SubmitFormBtn content="Tiếp theo" loading={loading} onClick={submit}/>
                </CardActions>
            </Card>
        </Box>
        </div>
    )
}
