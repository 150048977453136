import { Card, CardContent, Grid, TextField, Box, CardActions } from '@material-ui/core'
import React from 'react'
import styles from './register-info.css'
import SubmitFormBtn from '../submit-form-btn-component/submit-form-btn-component.jsx'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import ArrowBackIcon from '@material-ui/icons//ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const appearAnimation = {
    transition: 'all 300ms ease-out',
    transitionDelay: '200ms'
}

export default function RegisterInfoForm({defaultFullname = "", defaultPhonenumber = "", submitForm, error, loading = false,isInEditor}) {
    const [fullname, setfullname] = useState(defaultFullname);
    const [phonenumber, setphonenumber] = useState(defaultPhonenumber);
    const [clickSubmitted, setClickSubmitted] = useState(false);
    const [APIerror, setAPIerror] = useState("");
    const [Opacity, setOpacity] = useState(0);
    const [top, setTop] = useState('120px');

    useEffect(() => {
        setOpacity(1);
        setTop(0)
    }, [])

    useEffect(() => {
        if(error !== "") {
            setAPIerror(error)
        }
    }, [error])


    const onBlurFullName = (e) => {
        let value = e.target.value;
        let valArray = value.split(' ');
        let resultArray = []
        for(let i = 0; i < valArray.length; i++) 
            if(valArray[i] !== " " && valArray[i] !== "") 
            resultArray.push(valArray[i])
        
        let result = resultArray.join(" ");
        setfullname(result);
    }

    const checkPhonenumber = (e) => {
        let value = e.target.value;
        value = value.trim();
        value = value.replace(/\s/g, '');
        setphonenumber(value)
        if (value.length == 1 && (/[a-zA-Z]/).test(value)) {
            setphonenumber("")
            return;
        }
        var clearedNumberStr = "";
        if (value.length > 50) value = value.substring(0, 50);
        for (var i = 0; i < value.length; i++) {
            if (!isNaN(value.charAt(i)))
                clearedNumberStr += value.charAt(i);
        }
        
        if (clearedNumberStr.length > 12) {
            setphonenumber(clearedNumberStr.substring(0, 12));
        } else {
            setphonenumber(clearedNumberStr);
        }
        return;
    };

    const submit = (e) => {
        e.preventDefault()
        setClickSubmitted(true)
        if(fullname !== "" && phonenumber.length >= 9)
        submitForm(fullname, phonenumber)
    }

    return (
        <div style={{...appearAnimation, position: 'relative', opacity: Opacity, top: top, maxHeight:'535px'}}>
            {/* <Toaster position="top-center"/> */}
            <Grid className={isInEditor?'':'registerInfoGrid'} container  justifyContent="space-evenly">
            {!isInEditor&&<Grid component={Box} item md={6} display={{ xs: "none", md: "block" }}>
                    <img className={styles.kitten} src='/static/images/kitten-auth.png' alt="kitten"/>
                    </Grid>}  
                    <Grid item xs={isInEditor?0:12} md={isInEditor?0:6}>
                    <Card className={styles.form} style={{
                        width: isInEditor? '500px' : '90%',
                           borderRadius: isInEditor?0:57,
                           boxShadow: isInEditor?'':'0px 7px 11px rgba(0, 0, 0, 0.25)'
                    }}>
                        {!isInEditor ?
                        <Link to="/" className={styles.backToHomepage}><ArrowBackIosIcon fontSize={'inherit'}/>Trở về trang chủ</Link> : null}
                        <form onSubmit={submit}>
                        <h2 className={styles.formTitle}>Đăng ký tài khoản <br/>miễn phí</h2>
                        <CardContent className={styles.formContent}>    
                        <TextField className={styles.TextField} id="fullname" label="Họ và tên" variant="outlined" value={fullname} onBlur={e => onBlurFullName(e)} onChange={e => {setfullname(e.target.value); setAPIerror(""); }} autoFocus required/>
                        <p className={styles.error}>
                        {fullname === "" && clickSubmitted ? <p>Vui lòng nhập họ và tên</p> : ""}
                        </p>
                        <TextField className={styles.TextField} id="phonenumber" label="Số điện thoại" variant="outlined" value={phonenumber} onChange={e => {checkPhonenumber(e); setAPIerror(""); }} required/>
                        <p className={styles.error}>
                            {phonenumber === "" && clickSubmitted ? <p>Vui lòng nhập số điện thoại</p> : ""}
                            {phonenumber.length > 0 && phonenumber.length < 9 && clickSubmitted ? <p>Vui lòng nhập số điện thoại có thực</p> : ""}
                            <p>{APIerror}</p>
                        </p>
                        </CardContent>
                        <CardActions>
                            <SubmitFormBtn content="Đăng ký" type="submit" loading={loading} onClick={submit}/>
                        </CardActions>
                        {!isInEditor ? <CardActions>
                        <p className={styles.linkToLogin}>Bạn đã có tài khoản? <Link to="/login">Đăng nhập</Link></p>
                        </CardActions> : null}
                        <input type={'submit'} style={{display: 'none'}}/>
                        </form>
                    </Card>
                    </Grid>
                </Grid>
        </div>
    )
}
