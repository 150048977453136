import {GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_FAIL, REMOVE_USER_PROFILE, UPDATE_LOCAL_USER_PROFILE , UPDATE_LOCAL_USER_AVATAR,IS_AUTH_IN_EDITOR} from "../constants/constants";
import authApi from "../../../api/auth-api"

export const getUserProfile = (token) => (dispatch) => {
    // use Access_Token  to get profile
    // authApi.getProfile()  
    authApi.getProfile(token).then(response => {
        const data = response.data;
        if(response.message.status_code == 200) {
            const avatar = data.avatar;
            const username = data.username;
            const fullname = data.full_name;
            const id = data.id;
            
            console.log(token + " sad sd=")
            dispatch(getProfile(token, id, username, fullname, avatar,data.uniq_random_code))
        } else if(response.message.status_code == 500) {
            dispatch(removeProfile())
            dispatch(getProfileFailed("User does not exist"));
        } else {
            dispatch(removeProfile())
        }
        
    }).catch(error => {
        console.log(error);
        dispatch(removeProfile())
        dispatch(getProfileFailed(error));
    })
}

export const removeUserProfile = () => (dispatch) => {// call when user logout
    dispatch(removeProfile())
}

export const updateUserProfile = () => (dispatch) => {
    // update profile here

}

export const updateLocalUserAvatar = (avatar) => (dispatch) => {
    dispatch(updateAvatar(avatar)) // update local avatar
}
export const updateAuthInEditor=(isAuthInEditor)=>(dispatch)=>{
    dispatch({
        type:IS_AUTH_IN_EDITOR,
        data:{  isAuthInEditor:isAuthInEditor}
    })
  
   
}


const getProfile = (token, id, username, fullname, avatar,uniq_random_code) => ({
    type: GET_USER_PROFILE_SUCCESS,
    data: {
        token: token,
        id: id,
        username: username,
        fullname: fullname,
        // phonenumber: phonenumber,
        avatar: avatar,
        uniq_random_code:uniq_random_code
    }
})

const getProfileFailed = (error) => ({
    type: GET_USER_PROFILE_FAIL,
    data: {
        error: error
    }
})

const removeProfile = () => ({
    type: REMOVE_USER_PROFILE
})


const updateProfile = (data) => ({
    type: UPDATE_LOCAL_USER_PROFILE,
    data: data
})

const updateAvatar = (avatar) => ({
    type: UPDATE_LOCAL_USER_AVATAR,
    data: {avatar: avatar}
})