import React, { Component } from 'react';
import { Avatar } from '@material-ui/core';
class DefaultAvatar extends Component {
    constructor(props) {
        super(props);
    }

    shortname(fullname) {
        if(typeof fullname !== "undefined" && fullname !== null && fullname !== "" && fullname !== " ") {
            const firstChar = fullname.charAt(0).toUpperCase();
            var lastChar = ""
            for(let i = fullname.length-2; i>=0; i--) {
                if(fullname.charAt(i) === " ") {
                    lastChar = fullname.charAt(i+1).toUpperCase();
                    break;
                }
            }
            return firstChar + lastChar;
        } else {
            return "A";
        }
    }

    render(){
        const fontSize = this.props.fontSize;
        return (
            <Avatar alt="avatar" style={{backgroundColor: "#5DC2A7", position: "relative" , top:0 , left: 0, height: "100%", width: "100%", fontSize: fontSize}} src={this.props.avatar}>{this.shortname(this.props.fullname)}</Avatar>
        )
    }
}

export default DefaultAvatar;