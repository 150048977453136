import {FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL,
        CHANGE_FORGOTPASS_PHONENUMBER,
        VERIFY_OTP_FORGOTPASS_REQUEST, VERIFY_OTP_FORGOTPASS_SUCCESS, VERIFY_OTP_FORGOTPASS_FAIL, CLEAR_FORGOT_REDUX} from "../constants/constants"
const initialState = {
    countryCode: "+84",
    phonenumber: "",
    onStep: 1,
    forgot_token: "",
    waitingForResponse: false,
    error: ""
}

const forgotpassReducer = (state = initialState, action) => {
    switch(action.type) {
        case CLEAR_FORGOT_REDUX:
            return {
                countryCode: "+84",
                phonenumber: "",
                onStep: 1,
                forgot_token: "",
                waitingForResponse: false,
                error: ""
            }
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                waitingForResponse: true,
                error: ""
            }
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                error: "",
                phonenumber: action.data.phonenumber,
                onStep:2,
                waitingForResponse: false
            }
        case FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                error: action.data.error,
                waitingForResponse: false
            }
        case CHANGE_FORGOTPASS_PHONENUMBER:
            return {
                ...state,
                onStep: 1,
                waitingForResponse: false,
                data: "",
                error: ""
            }
        case VERIFY_OTP_FORGOTPASS_REQUEST:
            return {
                ...state,
                error: "",
                waitingForResponse: true
            }
        case VERIFY_OTP_FORGOTPASS_SUCCESS:
            return {
                ...state,
                onStep: 3,
                error: "",
                waitingForResponse: false,
                forgot_token: action.data.forgot_token
            }
        case VERIFY_OTP_FORGOTPASS_FAIL:
            return {
                ...state,
                error: action.data.error,
                waitingForResponse: false
            }
        
        default:
            return state;
    }
}
export default forgotpassReducer;