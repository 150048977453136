import projectData from './project-data';

/* eslint-disable import/no-unresolved */
import popWav from '!arraybuffer-loader!./83a9787d4cb6f3b7632b4ddfebf74367.wav';
import meowWav from '!arraybuffer-loader!./83c36d806dc92327b9e7049a565c6bff.wav';
import backdrop2 from '!raw-loader!./681d61108c519597ad9a083b311a285d.svg';
import costume1 from '!raw-loader!./0daed79b3772b9a84abcb680dbf01d8f.svg';
import costume2 from '!raw-loader!./54af394d60ed8ea16e51c5fcdd67b4f2.svg';
import costume3 from '!raw-loader!./ef608b34bfdf6d654cacd2e23c1a69b3.svg';
import backdrop1 from '!raw-loader!./8c6140b0812e2929d3812e4a0a671107.svg';

/* eslint-enable import/no-unresolved */

const defaultProject = translator => {
    let _TextEncoder;
    if (typeof TextEncoder === 'undefined') {
        _TextEncoder = require('text-encoding').TextEncoder;
    } else {
        /* global TextEncoder */
        _TextEncoder = TextEncoder;
    }
    const encoder = new _TextEncoder();

    const projectJson = projectData(translator);
    return [{
        id: 0,
        assetType: 'Project',
        dataFormat: 'JSON',
        data: JSON.stringify(projectJson)
    }, {
        id: '83a9787d4cb6f3b7632b4ddfebf74367',
        assetType: 'Sound',
        dataFormat: 'WAV',
        data: new Uint8Array(popWav)
    }, {
        id: '83c36d806dc92327b9e7049a565c6bff',
        assetType: 'Sound',
        dataFormat: 'WAV',
        data: new Uint8Array(meowWav)
    }, {
        id: '681d61108c519597ad9a083b311a285d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(backdrop2)
    }, {
        id: '0daed79b3772b9a84abcb680dbf01d8f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(costume1)
    }, {
        id: '54af394d60ed8ea16e51c5fcdd67b4f2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(costume2)
    }, {
        id: 'ef608b34bfdf6d654cacd2e23c1a69b3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(costume3)
    }, {
        id: '8c6140b0812e2929d3812e4a0a671107',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(backdrop1)
    }];
};

export default defaultProject;
