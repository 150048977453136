import {CHANGE_XY_IMAGE, GUI_CREATE_PROJECT_REQUEST, GUI_CREATE_PROJECT_SUCCESS, GUI_CREATE_PROJECT_ERROR, SET_NAME_PROJECT, PROJECT_DETAIL_SUCCESS, PROJECT_DETAIL_ERROR, UPLOAD_FILE_LOCAL_FAIL, START_UPLOAD_FILE_SERVER, STOP_UPLOAD_FILE_SERVER, ERROR_UPLOAD_FILE_SERVER, DISPATCH_SAVE_FILE_TO_SERVER, SET_PROJECT_JSON } from '../constants/constants';
//tạo mới 1 project
const initialState = {
  loading: true,
  nameProject: 'project chưa đặt tên',
  error: '',
  project: null,
}

const guiReducer = (state = initialState, action) => {
  switch (action.type) {
    case GUI_CREATE_PROJECT_REQUEST:
      return {
        ...state,

      };
    case GUI_CREATE_PROJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
        project: action.data.project,
        error: '',
      };
    }
    case GUI_CREATE_PROJECT_ERROR: {
      return {
        ...state,
        loading: false,
        project: null,
        error: action.data.error
      };
    }


    default:
      return state;
  }
};
//Thông tin chi tiết của 1 project
const initialStateProjectDetail = {
  error: '',
  creator: null,
  idProject: '',
  nameProject: ''
}
const projectDetailReducer = (state = initialStateProjectDetail, action) => {
  switch (action.type) {
    case PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        idProject: action.data.project.id,
        nameProject: action.data.project.name,
        creator: action.data.project.creator

      };
    case SET_NAME_PROJECT: {
      return {
        nameProject: action.data.nameProject,
        ...state,

      };
    }
    case PROJECT_DETAIL_ERROR:
      return {
        ...state,
        error: action.data.error
      }


    default:
      return state;
  }
}
const getProjectDetailSuccess = function (project) {
  return ({
    type: PROJECT_DETAIL_SUCCESS,
    data: { project }
  })
}

const setNameProject = function (nameProject) {
  return ({
    type: SET_NAME_PROJECT,
    data: { nameProject }
  })
}

const getProjectDetailError = function (error) {
  return ({
    type: PROJECT_DETAIL_ERROR,
    data: { error }
  })
}

//upload file local
const initialStateUploadFileLocal = {
  messageError: ''
}
const uploadFileLocalReducer = (state = initialStateUploadFileLocal, action) => {
  switch (action.type) {
    case UPLOAD_FILE_LOCAL_FAIL:

      return {
        messageError: action.data.error
      };

    default:
      return state;
  }
}
const onErrorUploadFileLocal = function (error) {
  return ({
    type: UPLOAD_FILE_LOCAL_FAIL,
    data: { error }
  });
}
//change x y ảnh
const initialStateChangeXYImage = {
  x: 0, 
  y: 0
}
const changeXYImageReducer = (state = initialStateChangeXYImage, action) => {
  switch (action.type) {
    case CHANGE_XY_IMAGE:

      return {
        x: action.data.x,
        y: action.data.y
      };

    default:
      return state;
  }
}
const setChangeXYImage = function (x,y) {
  return ({
    type: CHANGE_XY_IMAGE,
    data: { x,y }
  });
}

//uploadFile toServer
const initialStateUploadFileToServer = {
  projectJson: '',
  token: '',
  percent: 0,
  message: ''
}
const uploadFileServerReducer = (state = initialStateUploadFileToServer, action) => {
  switch (action.type) {
    case START_UPLOAD_FILE_SERVER:

      return {
        ...state,
        message: action.data.message,


      };
    case STOP_UPLOAD_FILE_SERVER:
      return {
        ...state,
        message: action.data.message,
        percent: action.data.percent
      }
    case DISPATCH_SAVE_FILE_TO_SERVER:
      return {
        ...state,
        token: action.data.token
      }


    case ERROR_UPLOAD_FILE_SERVER:
      return {
        ...state,
        message: action.data.messageError
      }


    default:
      return state;
  }
}
const initialStateProject = {
  projectJson: ''
}
const projectEditorReducer = (state = initialStateProject, action) => {
  switch (action.type) {
    case SET_PROJECT_JSON:
      return {
        ...state,
        projectJson: action.data.projectJson
      }
    default:
      return state;
  }
}
//tutorial


export {
  guiReducer as default,
  projectDetailReducer,
  initialStateProjectDetail,
  getProjectDetailSuccess,
  getProjectDetailError,
  setNameProject,
  uploadFileLocalReducer,
  initialStateUploadFileLocal,
  initialStateUploadFileToServer,
  onErrorUploadFileLocal,
  uploadFileServerReducer,
  projectEditorReducer,
  initialStateChangeXYImage,
  changeXYImageReducer,
  setChangeXYImage

};