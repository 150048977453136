import {defineMessages} from 'react-intl';

export default defineMessages({

    viet_nam_culture: {
        defaultMessage: 'Văn hoá Việt',
        description: 'Tag for filtering a library for animals',
        id: 'gui.libraryTags.viet_nam_culture'
    },
    
   
    robot: {
        defaultMessage: 'Người máy',
        description: 'Tag for filtering a library for fantasy',
        id: 'gui.libraryTags.robot'
    },
    plannet: {
        defaultMessage: 'Hành tinh',
        description: 'Tag for filtering a library for fashion',
        id: 'gui.libraryTags.plannet'
    },
    monster: {
        defaultMessage: 'Quái vật',
        description: 'Tag for filtering a library for food',
        id: 'gui.libraryTags.monster'
    },
    spaceship: {
        defaultMessage: 'Phi thuyền',
        description: 'Tag for filtering a library for indoors',
        id: 'gui.libraryTags.spaceship'
    },
    game: {
        defaultMessage: 'Trò chơi',
        description: 'Tag for filtering a library for music',
        id: 'gui.libraryTags.game'
    },
    
    ocean: {
        defaultMessage: 'Đại dương',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.ocean'
    },

    city: {
        defaultMessage: 'Thành phố',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.city'
    },
    sience: {
        defaultMessage: 'STEAM',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.sience'
    },
    universe: {
        defaultMessage: 'Vũ trụ',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.universe'
    },
    stage: {
        defaultMessage: 'Sân khấu',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.stage'
    },
    vehicle:{

        defaultMessage: 'Xe cộ',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.vehicle'

    },
    animal:{

        defaultMessage: 'Động vật',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.animal'

    },
    stadium: {
        defaultMessage: 'Sân vận động',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.stadium'
    },
   
    school: {
        defaultMessage: 'Trường học',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.school'
    },
    forest: {
        defaultMessage: 'Rừng',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.forest'
    },
    farm: {
        defaultMessage: 'Nông trại',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.farm'
    },
    construction: {
        defaultMessage: 'Công trình',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.construction'
    },
    nature: {
        defaultMessage: 'Thiên nhiên',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.nature'
    },
    person: {
        defaultMessage: 'Người',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.person'
    },
    vietnam: {
        defaultMessage: 'Việt Nam',
        description: 'Tag for filtering a library for outdoors',
        id: 'gui.libraryTags.vietnam'
    },
    animals: {
        defaultMessage: 'Animals',
        description: 'Tag for filtering a library for animals',
        id: 'gui.libraryTags.animals'
    },
    effects: {
        defaultMessage: 'Effects',
        description: 'Tag for filtering a library for effects',
        id: 'gui.libraryTags.effects'
    },
    loops: {
        defaultMessage: 'Loops',
        description: 'Tag for filtering a library for loops',
        id: 'gui.libraryTags.loops'
    },
    notes: {
        defaultMessage: 'Notes',
        description: 'Tag for filtering a library for notes',
        id: 'gui.libraryTags.notes'
    },
    percussion: {
        defaultMessage: 'Percussion',
        description: 'Tag for filtering a library for percussion',
        id: 'gui.libraryTags.percussion'
    },
    space: {
        defaultMessage: 'Space',
        description: 'Tag for filtering a library for space',
        id: 'gui.libraryTags.space'
    },
    sports: {
        defaultMessage: 'Sports',
        description: 'Tag for filtering a library for sports',
        id: 'gui.libraryTags.sports'
    },
    voice: {
        defaultMessage: 'Voice',
        description: 'Tag for filtering a library for voice',
        id: 'gui.libraryTags.voice'
    },
    wacky: {
        defaultMessage: 'Wacky',
        description: 'Tag for filtering a library for wacky',
        id: 'gui.libraryTags.wacky'
    },
    lich_su_viet:{
        defaultMessage: 'Lịch sử Việt',
        description: 'Tag for filtering a library for Lịch sử Việt',
        id: 'gui.libraryTags.lich_su_viet' 
    },
    van_hoa_viet:{
        defaultMessage: 'Văn hóa Việt',
        description: 'Tag for filtering a library for Văn hóa Việt',
        id: 'gui.libraryTags.van_hoa_viet' 
    },
    nguoi_may:{
        defaultMessage: 'Người máy',
        description: 'Tag for filtering a library for Người máy',
        id: 'gui.libraryTags.nguoi_may' 
    },
    quai_vat:{
        defaultMessage: 'Quái vật',
        description: 'Tag for filtering a library for Quái vật',
        id: 'gui.libraryTags.quai_vat' 
    },
    tro_choi:{
        defaultMessage: 'Trò chơi',
        description: 'Tag for filtering a library for Trò chơi',
        id: 'gui.libraryTags.tro_choi' 
    },
    vu_tru:{
        defaultMessage: 'Vũ trụ',
        description: 'Tag for filtering a library for Vũ trụ',
        id: 'gui.libraryTags.vu_tru' 
    },
    nguoi:{
        defaultMessage: 'Người',
        description: 'Tag for filtering a library for Người',
        id: 'gui.libraryTags.nguoi' 
    },
    dong_vat:{
        defaultMessage: 'Động vật',
        description: 'Tag for filtering a library for Động vật',
        id: 'gui.libraryTags.dong_vat' 
    },
    steam:{
        defaultMessage: 'STEAM',
        description: 'Tag for filtering a library for STEAM',
        id: 'gui.libraryTags.steam' 
    },
    su_kien:{
        defaultMessage: 'Sự Kiện',
        description: 'Tag for filtering a library for Sự Kiện',
        id: 'gui.libraryTags.su_kien' 
    }
});

