import {
    SEND_REGISTER_PHONENUMBER_REQUEST, SEND_REGISTER_PHONENUMBER_SUCCESS, SEND_REGISTER_PHONENUMBER_FAIL,
    CHANGE_USER_REGISTER_INFO,
    VERIFY_OTP_REGISTER_REQUEST, VERIFY_OTP_REGISTER_SUCCESS, VERIFY_OTP_REGISTER_FAIL,
    COMPLETE_REGISTER_REQUEST, COMPLETE_REGISTER_SUCCESS, COMPLETE_REGISTER_FAIL,
    UPDATE_REGISTER_INFO_REQUEST, UPDATE_REGISTER_INFO_SUCCESS, UPDATE_REGISTER_INFO_FAIL,
    UPDATE_REGISTER_AVT_REQUEST, UPDATE_REGISTER_AVT_SUCCESS, UPDATE_REGISTER_AVT_FAIL,
    CLEAR_REGISTER_STATES,
} from "../constants/constants";
import authApi from "../../../api/auth-api";
import profileApi from "../../../api/profile-api";
import {store} from '../store';
import { getUserProfile } from "../auth-reducer/auth-actions";
import { updateLocalUserAvatar } from "../auth-reducer/auth-actions";
export const registerFirstStep = (fullname, countryCode, phonenumber) => (dispatch) => {
    dispatch(registerPhonenumberRequest());
    console.log(store.getState().registerSource.utm_source)
    // call api
    console.log("first step " + fullname+ " " + phonenumber)
    authApi.registerUserInfo(countryCode + phonenumber, fullname).then(response => {
        const status_code = response.message.status_code;
        if (status_code == 200) {
            dispatch(registerPhonenumberSuccess(fullname, countryCode, phonenumber))
        } else if (status_code == 400) {
            if (typeof response.error[Object.keys(response.error)[0]] !== "undefined") {
                var error = "";
                if(response.error[Object.keys(response.error)[0]] === "Phone number is ready exists") 
                error = "Số điện thoại đã được sử dụng!";
                else if(response.error[Object.keys(response.error)[0]] === "The action you have requested is verry fast. Please try again latter")
                error = "Thao tác của bạn quá nhanh, vui lòng thử lại sau.";
                else if(response.message.text === "Invalid params!") {
                    if(response.error[Object.keys(response.error)[0]][0]=== "Phone number is not valid")
                    error = "Số điện thoại không hợp lệ!";
                    else
                    error = "Thông tin không hợp lệ!"
                }
                else error = "Đã có lỗi xảy ra, vui lòng thử lại sau";

                dispatch(registerPhonenumberFail(error))
            } else {
                dispatch(registerPhonenumberFail(response.message.text));
            }
        }
    }).catch(error => {
        console.log(error);
        dispatch(registerPhonenumberFail(error.name))
    });
}

export const changeUserRegisterInfo = () => (dispatch) => {
    dispatch(changeRegisterInfo());
}

export const clearRegisterState = () => (dispatch) => {
    dispatch({type: CLEAR_REGISTER_STATES})
}

export const registerSecondStep = (sms_code, countryCode, phonenumber) => (dispatch) => {
    dispatch(verifyOTPRequest());
    // call api
    authApi.verifyOTP(sms_code, countryCode + phonenumber).then(response => {
        const status_code = response.message.status_code;
        if (status_code == 200) {
            dispatch(verifyOTPSuccess(response.data.registration_code))
        } else if (status_code == 400) {
            dispatch(verifyOTPFail("Mã OTP không hợp lệ hoặc đã hết hạn!"))
        }
    }).catch(error => {
        console.log(error);
        dispatch(verifyOTPFail("Mã OTP không hợp lệ hoặc đã hết hạn!"))
    })
}

export const registerLastStep = (registration_code, password) => (dispatch) => {
    dispatch(completeRegisterRequest());
    let utm_source = store.getState().registerSource.utm_source;

    // call api
    authApi.registerPassword(registration_code, password, utm_source).then(response => {
        const status_code = response.message.status_code;
        if (status_code == 200) {
           console.log("aaccess_token to login: ", response.data.access_token)
            dispatch(completeRegisterSuccess(response.data.access_token));
           dispatch(getUserProfile(response.data.access_token))
        } else if (status_code == 400) {
            dispatch(completeRegisterFail("Đã lỗi xảy ra!"))
        }
    }).catch(error => {
        console.log(error);
        dispatch(completeRegisterFail("Đã lỗi xảy ra!"))
    })
}

export const updateRegisterInfo = (provinceCode, districtCode, schoolCode,auth) => (dispatch) => {
    dispatch(updateRegisterInfoRequest())
    // const auth = store.getState().auth;
    profileApi.updateProfile(auth.token, auth.id, auth.fullname, null, null, null, provinceCode, districtCode, schoolCode, null).then(response => {
        if(response.message.status_code == 200) {
            dispatch(updateRegisterInfoSuccess())
        } else {
            dispatch(updateRegisterInfoFail("Cập nhật thông tin không thành công!"))
        }
    }).catch(error => {
        dispatch(updateRegisterInfoFail("Cập nhật thông tin không thành công!"))
    })
}

export const updateRegisterAvt = (formData,auth) => (dispatch) => {
    // const auth = store.getState().auth;
    
    dispatch(updateRegisterAvtRequest())
    profileApi.updateAvatar(auth.token, auth.id, formData).then(response=> {
        if(response.message.status_code == 200) {
            dispatch(updateLocalUserAvatar(response.data.avatar))
            dispatch(updateRegisterAvtSuccess())
        } else dispatch(updateRegisterAvtFail("Cập nhật Avatar không thành công!"));
    }).catch( error => {
        console.log("Lỗi gọi api update avatar")
        dispatch(updateRegisterAvtFail("Cập nhật Avatar không thành công!"))
    })
}

const registerPhonenumberRequest = () => ({
    type: SEND_REGISTER_PHONENUMBER_REQUEST
});

const registerPhonenumberSuccess = (fullname, countryCode, phonenumber) => ({
    type: SEND_REGISTER_PHONENUMBER_SUCCESS,
    data: {
        countryCode: countryCode,
        phonenumber: phonenumber,
        fullname: fullname
    }
})

const registerPhonenumberFail = (error) => ({
    type: SEND_REGISTER_PHONENUMBER_FAIL,
    data: {
        error: error
    }
})

const changeRegisterInfo = () => ({
    type: CHANGE_USER_REGISTER_INFO
})

const verifyOTPRequest = () => ({
    type: VERIFY_OTP_REGISTER_REQUEST
});

const verifyOTPSuccess = (registrationCode) => ({
    type: VERIFY_OTP_REGISTER_SUCCESS,
    data: {
        registrationCode: registrationCode
    }
})

const verifyOTPFail = (error) => ({
    type: VERIFY_OTP_REGISTER_FAIL,
    data: {
        error: error
    }
})

const completeRegisterRequest = () => ({
    type: COMPLETE_REGISTER_REQUEST
})

const completeRegisterSuccess = (token) => ({
    type: COMPLETE_REGISTER_SUCCESS,
    data: {
        token: token
    }
})

const completeRegisterFail = (error) => ({
    type: COMPLETE_REGISTER_FAIL,
    data: {
        error: error
    }
})

const updateRegisterInfoRequest = () => ({type: UPDATE_REGISTER_INFO_REQUEST}) 
const updateRegisterInfoSuccess = () => ({type: UPDATE_REGISTER_INFO_SUCCESS}) 
const updateRegisterInfoFail = (error) => ({type: UPDATE_REGISTER_INFO_FAIL, data: {error}})

const updateRegisterAvtRequest = () => ({type: UPDATE_REGISTER_AVT_REQUEST}) 
export const updateRegisterAvtSuccess = () => ({type: UPDATE_REGISTER_AVT_SUCCESS}) 
const updateRegisterAvtFail = (error) => ({type: UPDATE_REGISTER_AVT_FAIL, data: {error}})
