import React from 'react'
import styles from './step-bar-component.css'
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import clsx from 'clsx';

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 22,
        left: 'calc(-50% + 30px)',
        right: 'calc(50% + 30px)',
    }, 
    active: {
    '& $line': {
        borderColor: 'rgb(255, 153, 0)',
    },
    },
    completed: {
    '& $line': {
        borderColor: '#1CC3A5',
    },
    },
    line: {
        borderColor: 'black',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: 'black',
        display: 'flex',
        height: 46,
        width: 46,
        fontWeight: 'bold',
        fontSize: '25px',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid #000000',
    },
    active: {
        backgroundColor: 'rgb(255, 153, 0)',
        color: "white!important",
        border: '0!important',
    },
    completed: {
        backgroundColor: '#1CC3A5',
        color: "white!important",
        border: '0!important',
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed, icon } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {icon}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

export default function StepBarComponent({ activeStep, steps,isInEditor }) {
    return (
        <div className={styles.stepBar} style={{paddingTop:isInEditor?0:50,paddingRight:isInEditor?50:0,paddingLeft:isInEditor?50:0}}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}><b>{label}</b></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}
