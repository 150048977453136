import React from 'react'
import { Box, Button, Card, CardActions, CardContent, TextField } from '@material-ui/core'
import SubmitFormBtn from '../submit-form-btn-component/submit-form-btn-component.jsx'
import styles from './forms-styles.css'
import DefaultAvatar from '../default-avatar/default-avatar.jsx'
import CollectionsIcon from '@material-ui/icons/Collections';
import { useState, useEffect } from 'react'
import {Redirect} from 'react-router-dom'
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom'

const appearAnimation = {
    transition: 'all 300ms ease-out',
    transitionDelay: '200ms'
}

export default function UpdateAvtForm({updateUserAvatar = () => {},onSkip=()=>{}, loading=false, fullname="", completedAllStep=false, error=""}) {
    const [selectedFile, setselectedFile] = useState(null)
    const [fileUrl, setfileUrl] = useState("");
    // const [completed, setcompleted] = useState(false)
    const [Opacity, setOpacity] = useState(0);
    const [left, setLeft] = useState('-100px');

    useEffect(() => {
        setOpacity(1);
        setLeft(0)
    }, [])

    useEffect(() => {
        if(error !== "") {
            toast.dismiss()
            toast.error(error, {duration: 3500})
        }
        return () => toast.dismiss()
    }, [error])

    const handleChangeFile = (e) => {
        if(typeof e.target.files[0] !== "undefined"){
            setselectedFile(e.target.files[0])
            setfileUrl(URL.createObjectURL(e.target.files[0]))
        }
        else {
            setselectedFile(null)
            setfileUrl("")
        }
    }

    const submit = () => {
        if(selectedFile !== null) {
            const formData = new FormData();
            formData.append("avatar",
            selectedFile, 
            selectedFile.name);
            updateUserAvatar(formData);
        } else {
            // setcompleted(true)
            onSkip();
        }
    }

    useEffect(() => {
        if(completedAllStep) {
            onSkip();
            // setcompleted(true)
        }
    }, [completedAllStep])

    // if(completed) return <Redirect to="/"/>   
    return (
        <Box className={styles.box} style={{...appearAnimation, position: 'relative', opacity: Opacity, left: left,}}>
            <Card className={styles.form}>
                <h2 className={styles.formTitle}>Cập nhật ảnh đại diện</h2>
                <CardContent>
                <figure className={styles.Avatar}>
                <DefaultAvatar fontSize={100} avatar={selectedFile != null ? fileUrl : null} fullname={fullname}/>
                </figure>
                <div className={styles.btnblock}><Button className={styles.selectImgBtn}><label className={styles.input_avatar_label} htmlFor={"update_avatar"}><CollectionsIcon/> chọn ảnh</label></Button></div>
                <input type="file" accept="image/*" name="photo" id={"update_avatar"} className={styles.update_avt_input} onChange={handleChangeFile}/>
                
                {/* {!loading ? <p className={styles.skipupdateAvatar}><Link className={styles.skipupdateAvatar} to="/">Để sau</Link></p> : ""} */}
                </CardContent>
                <CardActions>
                <SubmitFormBtn content="Hoàn thành" loading={loading} onClick={submit}/>
                </CardActions>
            </Card>
        </Box>
    )
}
