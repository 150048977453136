import React from 'react';
import styles from './submit-form-btn-component.css';
import Button from '@material-ui/core/Button';
import Loader from 'react-loader-spinner';

export default function SubmitFormBtn({content, onClick, loading = false}) {
    return (
        <>
            {loading? <Loader  className={styles.loading + " TailSpin"} type="Rings" color="#ffa31a" height={100} width={100}/>: <Button className={styles.button} onClick={onClick}>{content}</Button>}
        </>
    )
}
