import messages from './tag-messages.js';
// export default [
//     {tag: 'viet_nam_culture', intlLabel: messages.viet_nam_culture},
//     {tag: 'robot', intlLabel: messages.robot},
//     {tag: 'plannet', intlLabel: messages.plannet},
//     {tag: 'monster', intlLabel: messages.monster},
//     {tag: 'spaceship', intlLabel: messages.spaceship},
//     {tag: 'animal', intlLabel: messages.animal},
//     {tag: 'game', intlLabel: messages.game},
//     {tag: 'person', intlLabel: messages.person},
//     {tag: 'vehicle', intlLabel: messages.vehicle},
//     {tag: 'sience', intlLabel: messages.sience}
// ];
export default
    [
        {tag: 'lich_su_viet', intlLabel:messages.lich_su_viet },
        {tag: 'van_hoa_viet', intlLabel:messages.van_hoa_viet },
        {tag: 'nguoi_may', intlLabel:messages.nguoi_may },
        {tag: 'quai_vat', intlLabel:messages.quai_vat },
        {tag: 'tro_choi', intlLabel:messages.tro_choi },
        {tag: 'vu_tru', intlLabel:messages.vu_tru },
        {tag: 'nguoi', intlLabel:messages.nguoi },
        {tag: 'dong_vat', intlLabel:messages.dong_vat },
        {tag: 'steam', intlLabel:messages.steam },
        {tag: 'su_kien', intlLabel:messages.su_kien },
        ]
;

