import { GUI_CREATE_PROJECT_REQUEST, GUI_CREATE_PROJECT_SUCCESS, GUI_CREATE_PROJECT_ERROR, SET_NAME_PROJECT, START_UPLOAD_FILE_SERVER, STOP_UPLOAD_FILE_SERVER, ERROR_UPLOAD_FILE_SERVER, DISPATCH_SAVE_FILE_TO_SERVER, SET_PROJECT_JSON } from '../constants/constants';
import guiApi from '../../../api/gui-api';
import projectData from '../../../lib/default-project/project-data';
import { store } from '../store';
import { getUserProfile } from '../auth-reducer/auth-actions';
const projectJsonDefault = projectData();
export const creatProject = (name, projectJson = projectJsonDefault) => (dispatch) => {
  dispatch(createProjectRequest());
  console.log(projectJson);
  guiApi.createProject(name, projectJson).then(res => {
    console.log(res);
    dispatch(createProjectdSuccess(res.data));
  }).catch(err => {
    dispatch(createProjectdFailed(err));
  });
}
export const createProjectRequest = () => ({
  type: GUI_CREATE_PROJECT_REQUEST
});

export const createProjectdSuccess = (project) => ({
  type: GUI_CREATE_PROJECT_SUCCESS,
  data:
    { project }
  ,
});

export const createProjectdFailed = (error) => ({
  type: GUI_CREATE_PROJECT_ERROR,
  data: { error }
});

export const upLoadFileToServer = (percent, nameFile, dataBase64, typeAsset, token, uploadDone, projectJson) => dispatch => {
  dispatch({
    type: START_UPLOAD_FILE_SERVER,
    data: {
      message: `Đang tải file "${nameFile}" lên.`
    }
  });
  guiApi.upLoadAssetFile(nameFile, typeAsset, dataBase64, token).then(res => {

    dispatch({
      type: STOP_UPLOAD_FILE_SERVER,
      data: {
        percent, message: `Hoàn tất tải file "${nameFile}" lên.`
      }
    });
    if (uploadDone) {

      store.dispatch(setProjectJson(projectJson));

    }

  }).catch(error => {
    dispatch({
      type: ERROR_UPLOAD_FILE_SERVER,
      data: {
        percent, messageError: `Lỗi tải file "${nameFile}" lên.`
      }
    });
  })
}


 

export const dispatchSaveFileToServer = (token) => dispatch => {
  dispatch({
    type: DISPATCH_SAVE_FILE_TO_SERVER,
    data: {
      token
    }

  })
}
export const setProjectJson = (projectJson) => dispatch => {
  dispatch({
    type: SET_PROJECT_JSON,
    data: { projectJson }
  })
}
