// home
export const HOME_PROJECTS_REQUEST = "HOME_PROJECTS_REQUEST";
export const HOME_PROJECTS_SUCCESS = "HOME_PROJECTS_SUCCESS";
export const HOME_PROJECTS_ERROR = "HOME_PROJECTS_ERROR";

export const HOME_NEWS_REQUEST = "HOME_NEWS_REQUEST";
export const HOME_GET_NEWS_SUCCESS = "HOME_GET_NEWS_SUCCESS";
export const HOME_GET_NEWS_ERROR = "HOME_GET_NEWS_ERROR";

// auth
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";
export const REMOVE_USER_PROFILE = "REMOVE_USER_PROFILE";
export const UPDATE_LOCAL_USER_PROFILE = "UPDATE_LOCAL_USER_PROFILE";
export const UPDATE_LOCAL_USER_AVATAR = "UPDATE_LOCAL_USER_AVATAR";
export const IS_AUTH_IN_EDITOR="IS_AUTH_IN_EDITOR";


//register
export const SEND_REGISTER_PHONENUMBER_REQUEST = "SEND_REGISTER_PHONENUMBER_REQUEST";
export const SEND_REGISTER_PHONENUMBER_SUCCESS = "SEND_REGISTER_PHONENUMBER_SUCCESS";
export const SEND_REGISTER_PHONENUMBER_FAIL = "SEND_REGISTER_PHONENUMBER_FAIL";
export const CHANGE_USER_REGISTER_INFO = "CHANGE_USER_REGISTER_INFO";
export const VERIFY_OTP_REGISTER_REQUEST = "export const VERIFY_OTP_REGISTER_REQUEST";
export const VERIFY_OTP_REGISTER_SUCCESS = "VERIFY_OTP_REGISTER_SUCCESS";
export const VERIFY_OTP_REGISTER_FAIL = "VERIFY_OTP_REGISTER_FAIL";
export const COMPLETE_REGISTER_REQUEST = "export const COMPLETE_REGISTER_REQUEST";
export const COMPLETE_REGISTER_SUCCESS = "COMPLETE_REGISTER_SUCCESS";
export const COMPLETE_REGISTER_FAIL = "COMPLETE_REGISTER_FAIL";
export const UPDATE_REGISTER_INFO_REQUEST = "UPDATE_REGISTER_INFO_REQUEST";
export const UPDATE_REGISTER_INFO_SUCCESS = "UPDATE_REGISTER_INFO_SUCCESS";
export const UPDATE_REGISTER_INFO_FAIL = "UPDATE_REGISTER_INFO_FAIL";
export const UPDATE_REGISTER_AVT_REQUEST = "UPDATE_REGISTER_AVT_REQUEST";
export const UPDATE_REGISTER_AVT_SUCCESS = "UPDATE_REGISTER_AVT_SUCCESS";
export const UPDATE_REGISTER_AVT_FAIL = "UPDATE_REGISTER_AVT_FAIL";
export const CLEAR_REGISTER_STATES = "CLEAR_REGISTER_STATES";
export const SAVE_REGISTER_SOURCE = "SAVE_REGISTER_SOURCE";
export const EXPIRE_REGISTER_SOURCE = "EXPIRE_REGISTER_SOURCE";

//forgotpass
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const CHANGE_FORGOTPASS_PHONENUMBER = "CHANGE_FORGOTPASS_PHONENUMBER";
export const VERIFY_OTP_FORGOTPASS_REQUEST = "VERIFY_OTP_FORGOTPASS_REQUEST";
export const VERIFY_OTP_FORGOTPASS_SUCCESS = "VERIFY_OTP_FORGOTPASS_SUCCESS";
export const VERIFY_OTP_FORGOTPASS_FAIL = "VERIFY_OTP_FORGOTPASS_FAIL";
export const CLEAR_FORGOT_REDUX = "CLEAR_FORGOT_REDUX";

// gui
export const GUI_CREATE_PROJECT_REQUEST = "GUI_CREATE_PROJECT_REQUEST";
export const GUI_CREATE_PROJECT_SUCCESS = "GUI_CREATE_PROJECT_SUCCESS";
export const GUI_CREATE_PROJECT_ERROR = "GUI_CREATE_PROJECT_ERROR";
export const SET_NAME_PROJECT="SET_NAME_PROJECT";


//project detail --> in gui
export const PROJECT_DETAIL_SUCCESS = "PROJECT_DETAIL_REQUEST";
export const PROJECT_DETAIL_ERROR = "PROJECT_DETAIL_REQUEST";
//upLoad file local --> in gui
export const UPLOAD_FILE_LOCAL_FAIL="UPLOAD_FILE_LOCAL_FAIL";
//thay đổi vị trí --> in gui
export const CHANGE_XY_IMAGE ="CHANGE_XY_IMAGE";
//upload file server --> in gui
export const DISPATCH_SAVE_FILE_TO_SERVER= "DISPATCH_SAVE_FILE_TO_SERVER";
export const START_UPLOAD_FILE_SERVER="START_UPLOAD_FILE_SERVER";
export const STOP_UPLOAD_FILE_SERVER="STOP_UPLOAD_FILE_SERVER";
export const ERROR_UPLOAD_FILE_SERVER="ERROR_UPLOAD_FILE_SERVER";
export const SET_PROJECT_JSON="SET_PROJECT_JSON";

// projects-featured
export const FEATURED_OUTSTANDING_REQUEST = "FEATURED_OUTSTANDING_REQUEST";
export const FEATURED_OUTSTANDING_SUCCESS = "FEATURED_OUTSTANDING_SUCCESS";
export const FEATURED_OUTSTANDING_ERROR = "FEATURED_OUTSTANDING_ERROR";

export const FEATURED_MULTIPLAYER_REQUEST = "FEATURED_MULTIPLAYER_REQUEST";
export const FEATURED_MULTIPLAYER_SUCCESS = "FEATURED_MULTIPLAYER_SUCCESS";
export const FEATURED_MULTIPLAYER_ERROR = "FEATURED_MULTIPLAYER_ERROR";

export const FEATURED_MULTIVIEW_REQUEST = "FEATURED_MULTIVIEW_REQUEST";
export const FEATURED_MULTIVIEW_SUCCESS = "FEATURED_MULTIVIEW_SUCCESS";
export const FEATURED_MULTIVIEW_ERROR = "FEATURED_MULTIVIEW_ERROR";

//Themes--->in feature-project
export const FEATURED_THEMES_REQUEST = "FEATURED_THEMES_REQUEST";
export const FEATURED_THEMES_SUCCESS = "FEATURED_THEMES_SUCCESS";
export const FEATURED_THEMES_ERROR = "FEATURED_THEMES_ERROR";

//Contests--->in feature-project
export const FEATURED_CONTESTS_REQUEST = "FEATURED_CONTESTS_REQUEST";
export const FEATURED_CONTESTS_SUCCESS = "FEATURED_CONTESTS_SUCCESS";
export const FEATURED_CONTESTS_ERROR = "FEATURED_CONTESTS_ERROR";

// list-projects

export const LIST_PROJECTS_REQUEST = "LIST_PROJECTS_REQUEST";
export const LIST_PROJECTS_SUCCESS = "LIST_PROJECTS_SUCCESS";
export const LIST_PROJECTS_ERROR = "LIST_PROJECTS_ERROR";
export const SET_CURRENT_PAGE="SET_CURRENT_PAGE";
export const SET_TITLE_LIST_PROJECT="SET_TITLE_LIST_PROJECT";
export const RESET_STATE_LIST_PROJECT="RESET_STATE_LIST_PROJECT";



//profile page
export const GET_PROFILE_BY_ID_REQUEST = "GET_PROFILE_BY_ID_REQUEST"
export const GET_PROFILE_BY_ID_SUCCESS = "GET_PROFILE_BY_ID_SUCCESS"
export const GET_PROFILE_BY_ID_FAIL = "GET_PROFILE_BY_ID_FAIL"
export const TO_UPDATE_AVATAR = "TO_UPDATE_AVATAR"
export const TO_UPDATE_INFO = "TO_UPDATE_INFO"
export const BACK_TO_USER_HOMEPAGE = "BACK_TO_USER_HOMEPAGE"
export const UPDATE_PROFILE_AVATAR_REQUEST = "UPDATE_PROFILE_AVATAR_REQUEST"
export const UPDATE_PROFILE_AVATAR_SUCCESS = "UPDATE_PROFILE_AVATAR_SUCCESS"
export const UPDATE_PROFILE_AVATAR_ERROR = "UPDATE_PROFILE_AVATAR_ERROR"
export const UPDATE_PROFILE_INFO_REQUEST = "UPDATE_PROFILE_INFO_REQUEST"
export const UPDATE_PROFILE_INFO_SUCCESS = "UPDATE_PROFILE_INFO_SUCCESS"
export const UPDATE_PROFILE_INFO_ERROR = "UPDATE_PROFILE_INFO_ERROR"
export const GET_DVHC = "GET_DVHC"

// tutorials page
export const GET_TUTORIALS_IN_PAGE_REQUEST = "GET_TUTORIALS_IN_PAGE_REQUEST";
export const GET_TUTORIALS_IN_PAGE_SUCCESS = "GET_TUTORIALS_IN_PAGE_SUCCESS";
export const GET_TUTORIALS_IN_PAGE_FAIL = "GET_TUTORIALS_IN_PAGE_FAIL";

//project data json

export const GET_PROJECT_DATA_FILE_REQUEST = "GET_PROJECT_DATA_FILE_REQUEST";
export const GET_PROJECT_DATA_FILE_SUCCESS = "GET_PROJECT_DATA_FILE_SUCCESS";
export const GET_PROJECT_DATA_FILE_FAIL = "GET_PROJECT_DATA_FILE_FAIL";

//contest data
export const GET_ACTIVE_CONTESTS_REQUEST = "GET_ACTIVE_CONTESTS_REQUEST"
export const GET_ACTIVE_CONTESTS_SUCCESS = "GET_ACTIVE_CONTESTS_SUCCES"
export const GET_ACTIVE_CONTESTS_FAIL = "GET_ACTIVE_CONTESTS_FAIL"