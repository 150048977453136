import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet";
const SeoComponent = props => {
   console.log(window.location.href)
    return (
        <Helmet >
            <title>{`${props.title} - CodeKitten.vn`}</title>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta http-equiv="content-language" content="vi" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="author" content="Template" />
            <meta name="COPYRIGHT" content="© Template" />
            <meta name="robots" content="noodp,index,follow" />
            <meta name="google" content="notranslate" />
            <meta name="keywords" content="" />
            <meta name="description" content={`${props.description}`} />
            <meta property="og:image" content={`${props.image}`}></meta>
            <meta property="og:description" content={`${props.description}`}></meta>
            <meta property="og:title" content={`${props.title}`}></meta>
            <meta property="og:url" content={`${window.location.href}`}></meta>
            <meta property="og:type" content="article"></meta>
            <meta property="og:locale" content="vi_VN"></meta>
        </Helmet>
    )
}
SeoComponent.defaultProps={
    image:'/static/upload/head.png',
    title:'Nền tảng lập trình kéo thả thuần Việt đầu tiên tại Việt Nam',
    description:'Nền tảng lập trình kéo thả thuần Việt phát triển bởi Teky, trên nền công nghệ Scratch, MIT. Bộ thư viện hình nền, nhân vật, đối tượng lập trình được thiết kế gắn liền với lịch sử, anh hùng dân tộc, di sản nổi tiếng, danh lam thắng cảnh, bản sắc văn hoá',
    url:`${window.location.href}`
}

SeoComponent.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    url: PropTypes.string
}

export default SeoComponent
