import axiosClient from "./axios-client";
import queryString from 'query-string';
import {store} from "../reducers/codekitten/store"
const configHeader={
    'Content-Type': 'application/json',
                "Authorization": `Bearer ${store.getState().auth.token}`,
}
const baseUrlTest='https://d2119a29dd87.ngrok.io';
const guiApi = {
    createProject:(name,data)=>{
        return axiosClient.post('/api/v1/codekitten/projects',{name:name,data:data, remix:{
            "parent":1,
            "root":1
        }},{
            headers: {
                'Content-Type': 'application/json',
                            "Authorization": `Bearer ${store.getState().auth.token}`,
            }

        })
    },
    upLoadAssetFile:(nameFile,typeAsset,dataBase64,token)=>{
        //1 ảnh 2 là audio
        var type=1;

        if(typeAsset==='mp3'||typeAsset==='wav'){
            type=2
        }
        return axiosClient.post('/api/v1/codekitten/assets',{
            name:nameFile,
            type:type,
            data:dataBase64,
            data_format:typeAsset

        },{
            headers:{
                'Content-Type': 'application/json',
                            "Authorization": `Bearer ${token}`,
            }})
    },
    upDateProjectThumbnailImage:(idProject,dataImage)=>{
        
        const url=`/api/v1/codekitten/projects/${idProject}/thumbnail_set`;
        return axiosClient.post(url,{
            data:dataImage
        }, {
            headers:{
                'Content-Type': 'application/json',
                            "Authorization": `Bearer ${store.getState().auth.token}`,
            }
        })

    },
    getProjectDetail:(id)=>{
        return axiosClient.get(`/api/v1/codekitten/projects/${id}`)
    },
    upDateProject: (idProject,jsonVM, nameProject, description  ) => {
        const url = `/api/v1/codekitten/projects/${idProject}`;
        console.log('Sonnn Cap nhat project')
        return axiosClient.put(url, {
             name: nameProject,
            // description: description,
            data:JSON.parse(jsonVM)
        }, {
            headers: {
                'Content-Type': 'application/json',
                            "Authorization": `Bearer ${store.getState().auth.token}`,
            }
        });
    },
    upDateDescriptionProject: (idProject,description  ) => {
        const url = `/api/v1/codekitten/projects/${idProject}`;

        return axiosClient.put(url, {
             description: description
        }, {
            headers: {
                'Content-Type': 'application/json',
                            "Authorization": `Bearer ${store.getState().auth.token}`,
            }
        });
    },
    getTutorial:(id)=>{
        return axiosClient.get(`/api/v1/codekitten/tutorials/${id}`);
    }
}
export default guiApi;
