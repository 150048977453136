
import axiosClient from "./axios-client";
const projectsApi = {
    getMyProjects: (id, page, per_page) => {
        const url = `/api/v1/codekitten/users/${id}/projects?`;
        return axiosClient.get(url, {
            params: {
                page: page,
                per_page: per_page
            }
        });
    }, 
      getMyProjectsByUniqRandomCode: (uniqRandomCode, page, per_page) => {
        const url = `/api/v1/codekitten/user/${uniqRandomCode}/projects`;
        return axiosClient.get(url, {
            params: {
                page: page,
                per_page: per_page
            }
        });
    },

    getProjects: (params) => {
        const url = '/api/v1/codekitten/projects';
        return axiosClient.get(url, {
            params
        });
    },

    getProjectDetail: (id) => {
        const url = `/api/v1/codekitten/projects/${id}`;
     
        return axiosClient.get(url,{withCredentials:true});
    },
    //get file json 
    getProjectDataFile: (idProject)=>{
        const link_get_file=`/api/v1/codekitten/projects/${idProject}/project_data_get`;
        return axiosClient.get(link_get_file);
    },

    deleteMyProject:(project_id, token) => {
        const url = `/api/v1/codekitten/projects/${project_id}`;
        const header = {
            Authorization: "Bearer " + token,
        }
        return axiosClient.delete(url,{headers: header});
    },
    countViewProject:(projectId)=>{
        return axiosClient.get(`/api/v1/codekitten/projects/${projectId}/view_count`)
    },
    submitProjectToContest:(projectId, contestId, token)=> {
        const url = `/v1/code_kittens_api/projects/${projectId}/join_contest/`
        return axiosClient.post(url,
            {
                contest_id: contestId
            },
            {headers: {
                "Authorization": `Bearer ${token}`
            }})
    }
}
export default projectsApi;
