import {SEND_REGISTER_PHONENUMBER_REQUEST,SEND_REGISTER_PHONENUMBER_SUCCESS, SEND_REGISTER_PHONENUMBER_FAIL,
        CHANGE_USER_REGISTER_INFO,
        VERIFY_OTP_REGISTER_REQUEST,VERIFY_OTP_REGISTER_SUCCESS, VERIFY_OTP_REGISTER_FAIL,
    COMPLETE_REGISTER_REQUEST,COMPLETE_REGISTER_SUCCESS, COMPLETE_REGISTER_FAIL,
    UPDATE_REGISTER_INFO_REQUEST, UPDATE_REGISTER_INFO_SUCCESS, UPDATE_REGISTER_INFO_FAIL,
    UPDATE_REGISTER_AVT_REQUEST, UPDATE_REGISTER_AVT_SUCCESS, UPDATE_REGISTER_AVT_FAIL,
    CLEAR_REGISTER_STATES
} from "../constants/constants";

const initialState = {
    countryCode: "+84",
    phonenumber: "",
    fullname: "",
    registrationCode: "",
    onStep: 1,
    waitingForResponse: false,
    error: "",
    registerSuccess: false,
    completedAllStep: false,
    token: ""
}

const registerReducer = (state = initialState, action) => {
    switch(action.type) {
        case SEND_REGISTER_PHONENUMBER_REQUEST:
            return {
                ...state,
                waitingForResponse: true,
                error: "",
                completedAllStep: false
            }
        case SEND_REGISTER_PHONENUMBER_SUCCESS:
            return {
                ...state,
                error: "",
                fullname: action.data.fullname,
                phonenumber: action.data.phonenumber,
                countryCode: action.data.countryCode,
                onStep: 2,
                waitingForResponse: false
            }
        case SEND_REGISTER_PHONENUMBER_FAIL:
            return {
                ...state,
                error: action.data.error,
                waitingForResponse: false
            }
        case CHANGE_USER_REGISTER_INFO:
            return {
                ...state,
                onStep:1,
                error: "",
                waitingForResponse: false
            }
        case VERIFY_OTP_REGISTER_REQUEST:
            return {
                ...state,
                error:"",
                waitingForResponse: true
            }
        case VERIFY_OTP_REGISTER_SUCCESS:
            return {
                ...state,
                error: "",
                onStep:3,
                registrationCode: action.data.registrationCode,
                waitingForResponse: false
            }
        case VERIFY_OTP_REGISTER_FAIL:
            return {
                ...state,
                error: action.data.error,
                waitingForResponse: false,
            }
        case COMPLETE_REGISTER_REQUEST:
            return {
                ...state,
                error:"",
                waitingForResponse: true
            }
        case COMPLETE_REGISTER_SUCCESS:
            return {
                ...state,
                error: "",
                registerSuccess: true,
                waitingForResponse: false,
                token: action.data.token,
                onStep: 4
            };
        case COMPLETE_REGISTER_FAIL:
            return {
                ...state,
                error: action.data.error,
                registereSuccess: false,
                waitingForResponse: false
            }
        case UPDATE_REGISTER_INFO_REQUEST:
            return {
                ...state,
                waitingForResponse: true,
                error: ""
            }
        case UPDATE_REGISTER_INFO_SUCCESS:
            return {
                ...state,
                waitingForResponse: false,
                onStep: 5,
                error: ""
            }
        case UPDATE_REGISTER_INFO_FAIL:
            return {
                ...state,
                waitingForResponse: false,
                error: action.data.error
            }
        case UPDATE_REGISTER_AVT_REQUEST:
            return {
                ...state,
                error: "",
                waitingForResponse: true,
            }
        case UPDATE_REGISTER_AVT_SUCCESS:
            return {
                ...state,
                error: "",
                waitingForResponse: false,
                completedAllStep: true
            }
        case UPDATE_REGISTER_AVT_FAIL:
            return {
                ...state,
                error: action.data.error,
                waitingForResponse: false
            }
        case CLEAR_REGISTER_STATES:
            return {
                countryCode: "+84",
                phonenumber: "",
                fullname: "",
                registrationCode: "",
                onStep: 1,
                waitingForResponse: false,
                error: "",
                registerSuccess: false,
                completedAllStep: false,
                token: ""
            }
        default:
            return state;
    }

} 
export default registerReducer;