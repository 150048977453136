import React from 'react'
import { Box, Card, CardActions, CardContent, TextField } from '@material-ui/core'
import SubmitFormBtn from '../submit-form-btn-component/submit-form-btn-component.jsx'
import styles from './forms-styles.css'
import Select from 'react-select';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import profileApi from '../../../api/profile-api.js';
const appearAnimation = {
    transition: 'all 300ms ease-out',
    transitionDelay: '200ms'
}

export default function UpdateInfoForm({loggedIn, getDVHC, dvhc, loading, submitForm, error}) {
    const [ProvinceOptions, setProvinceOptions] = useState([])
    const [DistrictOptions, setDistrictOptions] = useState([])
    const [SchoolOptions, setSchoolOptions] = useState([])
    const [crtPorvince, setCrtProvince] = useState({value: "", label: ""})
    const [crtDistrict, setCrtDistrict] = useState({value: "", label: ""})
    const [crtSchool, setCrtSchool] = useState({value: "", label: ""})
    const [loadingSchools, setloadingSchools] = useState(false);

    const [Opacity, setOpacity] = useState(0);
    const [left, setLeft] = useState('-100px');

    useEffect(() => {
        setOpacity(1);
        setLeft(0)
    }, [])

    const getSchools = (districtCode) => {
        console.log(loadingSchools + " " + loading)
        setloadingSchools(true);
        profileApi.getSchoolsByDistrictCode(districtCode).then(response => {
            if(response.data.length > 0) {
                let Schools = response.data.map(({
                    name: label, code: value, ...rest
                    }) => ({
                    value, label, ...rest}));

                setSchoolOptions(Schools);
                setCrtSchool(Schools[0])
            } else {
                setSchoolOptions([]);
                setCrtSchool({value: "", label: ""})
            }
            setloadingSchools(false)
        }).catch(error => {
            console.log(error)
            setloadingSchools(false)
        })
    }

    useEffect(() => {
        if(error !== "") {
            toast.dismiss()
            toast.error(error, {duration: 3500})
        }
    }, [error])
    
    useEffect(() => {
        if(typeof dvhc === 'undefined' || dvhc == null || dvhc.length == 0) {
            getDVHC()
        } else {
            let Provinces = dvhc.map(({
            name: label, code: value, ...rest
            }) => ({
            value, label, ...rest}));
            setProvinceOptions(Provinces)
            if(dvhc.length>0) {
                setCrtProvince({value: dvhc[0].code, label: dvhc[0].name})
                let Districts = dvhc[0].districts.map(({
                name: label, code: value, ...rest
                }) => ({label, value, ...rest}))
                setDistrictOptions(Districts)
                setCrtDistrict(Districts[0])
                getSchools(Districts[0].value)
            }
        }
    }, [dvhc])

    const onChangeProvince = (option) => {
        setCrtProvince(option)
        console.log(option.districts)
        let Districts = option.districts.map(({
        name: label, code: value, ...rest
        }) => ({label, value, ...rest}))
        setDistrictOptions(Districts)
        setCrtDistrict(Districts[0])
        getSchools(Districts[0].value)
    }

    const onChangeDistrict = (option) => {
        setCrtDistrict(option)
        console.log(option)
        getSchools(option.value)
    }

    const submit = () => {
        submitForm(crtPorvince.value, crtDistrict.value, crtSchool.value)
    }
    return (
        <Box className={styles.box} style={{...appearAnimation, position: 'relative', opacity: Opacity, left: left,}}>
            <Card className={styles.form}>
                {loggedIn && typeof dvhc !== "undefined" && dvhc != null && dvhc.length !== 0 ? 
                <>
                <h2 className={styles.formTitle}>Thông tin của bạn</h2>
                <CardContent>
                <ul className={styles.updateInfoUl}>
                    <li className={styles.updateInfoLi}>
                    <p className={styles.updateInfoTag}>Tỉnh/Thành phố:</p>
                    <Select className={styles.updateInfoField} options={ProvinceOptions} placeholder="..." value={crtPorvince} onChange={option => onChangeProvince(option)}
                    noOptionsMessage={() => 'Không có lựa chọn!'}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, fontSize: 20, fontWeight: 500, zIndex: 9999 }) }}/>
                    </li>
                    <li className={styles.updateInfoLi}>
                    <p className={styles.updateInfoTag}>Quận/huyện:</p>
                    <Select className={styles.updateInfoField} options={DistrictOptions} placeholder="" value={crtDistrict} onChange={option => onChangeDistrict(option)}
                    noOptionsMessage={() => 'Không có lựa chọn!'}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, fontSize: 20, fontWeight: 500, zIndex: 9999 }) }}/>
                    </li>
                    {SchoolOptions != null && SchoolOptions.length > 0 ? <li className={styles.updateInfoLi}>
                    <p className={styles.updateInfoTag}>Trường học:</p>
                    <Select className={styles.updateInfoField} options={SchoolOptions} placeholder="" value={crtSchool} onChange={option => setCrtSchool(option)}
                    noOptionsMessage={() => 'Không có lựa chọn!'}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, fontSize: 20, fontWeight: 500, zIndex: 9999 }) }}/>
                    </li> : ""}
                </ul>
                </CardContent>
                <CardActions>
                <SubmitFormBtn content="Tiếp theo" loading={loading || loadingSchools} onClick={submit}/>
                </CardActions>
                </>
                : <CardActions><SubmitFormBtn content="Tiếp theo" loading={true}/></CardActions>}
            </Card>
        </Box>
    )
}
