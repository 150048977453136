import { HOME_PROJECTS_REQUEST, HOME_PROJECTS_SUCCESS, HOME_PROJECTS_ERROR,
      HOME_NEWS_REQUEST,  HOME_GET_NEWS_SUCCESS, HOME_GET_NEWS_ERROR} from '../constants/constants';
  
  const initialState = {
    loadingProjects: true,
    loadingNews: true,
    news: [],
    projects: [],
    projectsError: '',
    newsError: ''
  }
  
   const homeReducer = (state = initialState, action) => {
    switch (action.type) {
      case HOME_PROJECTS_REQUEST:
        return {
         ...state,
         projects: [],
         projectsError: '',
         loadingProjects: true
        };
      case HOME_PROJECTS_SUCCESS: {
        return {
          ...state,
          loadingProjects: false,
          projects: action.data.projects,
          projectsError: ''
        };
      }
      case HOME_PROJECTS_ERROR: {
        return {
          ...state,
          loadingProjects: false,
          projects: [],
          projectsError: action.data.error
        }
      }
      case HOME_NEWS_REQUEST: {
        return {
          ...state,
          news: [],
          newsError: '',
          loadingNews: true
        }
      }
      case HOME_GET_NEWS_SUCCESS: {
        return {
          ...state,
          loadingNews: false,
          news: action.data.news,
          newsError: ''
        }
      }
      case HOME_GET_NEWS_ERROR: {
        return {
          ...state,
          loadingNews: false,
          newsError: action.data.error
        }
      }

      default:
        return state;
    }
  };
  export default homeReducer;