import messages from './tag-messages.js';
export default [
    // {tag: 'ocean', intlLabel: messages.ocean},
    // {tag: 'city', intlLabel: messages.city},
    // {tag: 'universe', intlLabel: messages.universe},
    // {tag: 'stage', intlLabel: messages.stage},
    // {tag: 'construction', intlLabel: messages.construction},
    // {tag: 'nature', intlLabel: messages.nature}
    {tag: 'su_kien', intlLabel: messages.su_kien}
];
