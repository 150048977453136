import { Card, CardContent, Grid, TextField, Box, CardActions, FormHelperText, FormControl, IconButton } from '@material-ui/core'
import React from 'react'
import VisibilityIcon from '@material-ui/icons//Visibility';
import VisibilityOffIcon from '@material-ui/icons//VisibilityOff';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import styles from './login-form.css'
import SubmitFormBtn from '../submit-form-btn-component/submit-form-btn-component.jsx'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const appearAnimation = {
    transition: 'all 300ms ease-out',
    transitionDelay: '200ms'
}

export default function LoginForm({ submitForm, error, loading = false, isInEditor }) {
    const [phonenumber, setphonenumber] = useState("");
    const [password, setPassword] = useState("")
    const [clickSubmitted, setClickSubmitted] = useState(false);
    const [APIerror, setAPIerror] = useState("");
    const [Opacity, setOpacity] = useState(0);
    const [showPW, setshowPW] = useState(false)
    const [top, setTop] = useState('120px');

    useEffect(() => {
        setOpacity(1);
        setTop(0)
    }, [])

    useEffect(() => {
        if (error !== "") {
            setAPIerror(error)
            document.getElementById('login-phonenumber').focus()
        }
    }, [error])

    const checkPhonenumber = (e) => {
        let value = e.target.value;
        value = value.trim();
        value = value.replace(/\s/g, '');
        setphonenumber(value)
        if (value.length == 1 && (/[a-zA-Z]/).test(value)) {
            setphonenumber("")
            return;
        }
        var clearedNumberStr = "";
        if (value.length > 50) value = value.substring(0, 50);
        for (var i = 0; i < value.length; i++) {
            if (!isNaN(value.charAt(i)))
                clearedNumberStr += value.charAt(i);
        }

        if (clearedNumberStr.length > 12) {
            setphonenumber(clearedNumberStr.substring(0, 12));
        } else {
            setphonenumber(clearedNumberStr);
        }
        return;
    };

    const onChangePW = (e) => {
        let value = e.target.value;
        value = value.trim();
        value = value.replace(/\s/g, '');
        setPassword(value);
    }

    const triggerPWVisible = () => {
        setshowPW(!showPW)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setClickSubmitted(true)
        if (phonenumber.length >= 9 && password.length > 0) {
            console.log(phonenumber + " " + password)
            submitForm(phonenumber, password)
        }
    }

    return (
        <div style={{ ...appearAnimation, position: 'relative', opacity: Opacity, top: top, maxHeight: '535px' }}>
            <Grid className={isInEditor ? '' : 'registerInfoGrid'} container justifyContent="space-evenly">
                {!isInEditor && <Grid component={Box} item md={6} display={{ xs: "none", md: "block" }}>
                    <img className={styles.kitten} src='/static/images/kitten-auth.png' alt="kitten" />
                </Grid>}
                <Grid item xs={isInEditor ? 0 : 12} md={isInEditor ? 0 : 6}>
                    <Card className={styles.form} style={{
                        width: isInEditor ? '500px' : '90%',
                        borderRadius: isInEditor ? 0 : 57,
                        boxShadow: isInEditor ? '' : '0px 7px 11px rgba(0, 0, 0, 0.25)'
                    }}>
                        <h2 className={styles.formTitle}>Đăng nhập</h2>
                        <CardContent className={styles.formContent}>
                            <Box component={'form'} onSubmit={onSubmit}>
                                <TextField className={styles.TextField + " " + styles.mb_15} id="login-phonenumber" label="Số điện thoại" variant="outlined" type="text"
                                    value={phonenumber} onChange={e => { checkPhonenumber(e); setAPIerror(""); }} autoFocus={1} required/>
                                <p className={styles.error}>
                                    {phonenumber === "" && clickSubmitted ? <p>Vui lòng nhập số điện thoại</p> : ""}
                                    {phonenumber.length > 0 && phonenumber.length < 9 && clickSubmitted ? <p>Vui lòng nhập số điện thoại có thực</p> : ""}
                                </p>
                                <Box className={styles.PWContainer}>
                                <TextField className={styles.PwField} id="login-password" label="Mật khẩu" variant="outlined" type={showPW? 'text':"password"}
                                    onChange={e => { onChangePW(e); setAPIerror(""); }} value={password} required
                                />
                                <IconButton size='small' className={styles.TriggerShowPW} 
                                onClick={triggerPWVisible}>{showPW? <VisibilityOffIcon/> : <VisibilityIcon/>}</IconButton>
                                </Box>
                                <p className={styles.error + " " + styles.pwError}>
                                    {password.length == 0 && clickSubmitted ? <p>Vui lòng nhập mật khẩu!</p> : ""}
                                    <p>{APIerror}</p>
                                </p>
                                <input type={'submit'} style={{display: 'none'}}/>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Box className={styles.logintActions}>
                                <SubmitFormBtn content="Đăng nhập" type="submit" loading={loading} onClick={onSubmit} />
                                <Link className={styles.linktoForgetPW} target={`${isInEditor ? "_blank" : ''}`} to="/forgotpassword">Quên mật khẩu</Link>
                            </Box>
                        </CardActions>
                        {!isInEditor ? <CardActions>
                            <p className={styles.linkToLogin}>Bạn chưa có tài khoản? <Link to="/register">Đăng ký ngay</Link></p>
                        </CardActions> : null}
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
