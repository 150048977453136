import React from 'react'
import { Box, Card, CardActions, CardContent, TextField } from '@material-ui/core'
import SubmitFormBtn from '../submit-form-btn-component/submit-form-btn-component.jsx'
import styles from './forms-styles.css'
import { useState, useEffect } from 'react'
import toast from 'react-hot-toast';

const appearAnimation = {
    transition: 'all 300ms ease-out',
    transitionDelay: '200ms'
}

export default function RegisterPWForm({ submitForm, error, loading}) {
    const [password, setPassword] = useState("")
    const [leftPWField, setleftPWField] = useState(false)
    const [reTypedPassword, setReTypedPassword] = useState("")
    const [leftRetypedPWField, setleftRetypedPWField] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [Opacity, setOpacity] = useState(0);
    const [left, setLeft] = useState('-100px');

    useEffect(() => {
        setOpacity(1);
        setLeft(0)
    }, [])

    useEffect(() => {
        toast.dismiss()
        if(error !== "") {
            toast.error(error, {duration: 3500})
        }
    }, [error])

    const validation = (pw) => {
        return /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(pw);
    }

    const onChangePW = (e) => {
        setShowAlert(false)
        let value = e.target.value;
        value = value.trim();
        value = value.replace(/\s/g, '');
        setPassword(value);
    }

    const onChangeretypedPW = (e) => {
        setShowAlert(false)
        let value = e.target.value;
        value = value.trim();
        value = value.replace(/\s/g, '');
        setReTypedPassword(value);
    }

    const submit = (e) => {
        e.preventDefault()
        if(!validation(password)) return
        if(password !== reTypedPassword) {
            setShowAlert(true)
            return
        }
        submitForm(password)
    }
    
    return (
        <Box className={styles.box} style={{...appearAnimation, position: 'relative', opacity: Opacity, left: left,}}>
            <Card className={styles.form}>
                <h2 className={styles.formTitle}>Nhập mật khẩu của bạn</h2>
                <form onSubmit={submit}>
                <CardContent className={styles.pwformContent}>
                <TextField className={styles.pwTextField} id="password" label="Mật khẩu" variant="outlined" type="password"
                value={password} onChange={onChangePW} onBlur={() => setleftPWField(true)} error={!validation(password) && leftPWField} 
                color="primary" required autoFocus={1}/>
                <p className={styles.error + " " + styles.pwError}>
                    {!validation(password) && leftPWField && password.length > 0 ? <p style={{maxWidth:'500px'}}>Mật khẩu phải có từ 8 ký tự trở lên bao gồm chữ HOA, chữ thường, chữ số và có thể có ký tự đặc biệt.</p> : ""}
                    {password.length == 0 && leftPWField ? <p>Không được để trống!</p> : null}
                </p>
                <TextField className={styles.pwTextField} id="retypedPW" label="Nhập lại mật khẩu" variant="outlined" type="password"
                value={reTypedPassword} onChange={onChangeretypedPW}
                onBlur={() => setleftRetypedPWField(true)} error={!validation(reTypedPassword) && leftRetypedPWField}required/>
                <p className={styles.error + " " + styles.pwError}>
                    {!validation(reTypedPassword) && leftRetypedPWField && !showAlert && reTypedPassword.length > 0? <p style={{maxWidth:'500px'}}>Mật khẩu phải có từ 8 ký tự trở lên bao gồm chữ HOA, chữ thường, chữ số và có thể có ký tự đặc biệt.</p> : ""}
                    {!showAlert && reTypedPassword.length == 0 && leftRetypedPWField ? <p>Không được để trống!</p> : null}
                    {showAlert? <p>Nhập lại mật khẩu không chính xác</p> : ""}
                </p>
                </CardContent>
                <CardActions>
                <SubmitFormBtn content="Tiếp theo" loading={loading} onClick={submit}/>
                </CardActions>
                <input type={'submit'} style={{display: 'none'}}/>
                </form>
            </Card>
        </Box>
    )
}
