import React from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';
 const MyDialogs=(props)=> {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
   
  };
  const handleClose = () => {
     console.log('Đóng')
    setOpen(false);
    props.onCloseDialog()
  };

  return (
    <div>
       <a style={{cursor: "pointer"}} onClick={handleClickOpen}>
      {props.title}
      </a>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div>
            {props.children}
        </div>
      </Dialog>
    </div>
  );
}
MyDialogs.defaultProps={
  onCloseDialog:()=>{}

}
MyDialogs.propTypes={
    title:PropTypes.string.isRequired,
    onCloseDialog: PropTypes.func
}
export default MyDialogs